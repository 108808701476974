import React, { useState, useEffect, useRef } from 'react';
import ToolkitProvider, {
  CSVExport,
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import Button from '../../UIElements/Button/UtilsButton';
import PageContainer from '../../UIElements/Containers';
import SlideUpModal from '../../Modals/SlideUpModal';
import { toast } from 'react-toastify';
import GroupSelect from "../../Forms/Elements/GroupSelect";
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import {
  getWorkSitesForCorporate,
  resetGetCheckInCallRecordError,
  resetUpdateCheckInCallRecordData,
  resetUpdateCheckInCallRecordError,
  getCallRecordingUrl,
  getCorporateCheckInVisits,
  updateCheckInCallRecord,
} from '../../../api-actions/corporateDataAction';
import {
  resetWorkSiteErrorData,
} from '../../../api-actions/workSiteDataAction';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { RiShareBoxFill } from 'react-icons/ri';
import { FiShare } from 'react-icons/fi';
import Loader from '../../Loader/Loader';
import NoData from '../../NoData/NoData';
import { Link } from 'react-router-dom';

const CallRecords = () => {
  const { ExportCSVButton } = CSVExport;
  const jwtToken = localStorage.getItem('jwtToken');
  const callRecordEmptyState = {
    checkOutTechPhoneNo: '',
    checkOutWorkerName: '',
    workerStatus: '',
    checkOut: '',
  };

  let endDate = new Date();
  endDate.setDate(endDate.getDate() + 30);
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.AdminAuthData.userId);
  const callRecords = useSelector((state) => state.CorporateData.getCorporateCheckInVisitsData);
  const callRecordsErrorData = useSelector((state) => state.CorporateData.getCorporateCheckInVisitsError);
  const workSitesForCorporateData = useSelector((state) => state.CorporateData.getWorkSitesForCorporateData);
  const workSitesForCorporateError = useSelector((state) => state.CorporateData.getWorkSitesForCorporateError);

  const updateCheckInCallRecordData = useSelector((state) => state.CorporateData.updateCheckInCallRecordData);
  const updateCheckInCallRecordError = useSelector((state) => state.CorporateData.updateCheckInCallRecordError);

  const [workerStatus, setWorkerStatus] = useState('');
  const [callRecordsWithIndex, setCallRecordsWithIndex] = useState([]);
  const [exportCheckInVistsData, setExportCheckInVisitsData] = useState();
  const [startDateRange, setStartDateRange] = useState();
  const [endDateRange, setEndDateRange] = useState();
  const [currentCallRecordId, setCurrentCallRecordId] = useState(null);
  const [callRecord, setCallRecord] = useState(callRecordEmptyState)
  const [worksiteOptionsForFilter, setWorksiteOptionsForFilter] = useState([]);
  const [worksiteId, setWorksiteId] = useState('');
  const workerStatusFilterOptions = [{ value: 'all', label: 'All Status' }, { value: 'completed', label: 'Completed' }, { value: 'additional work found', label: 'Additional Work Found' }, { value: 'requires part', label: 'Requires Part' }, { value: 'requires follow-up', label: 'Requires Follow-up' }, { value: 'requires authorization', label: 'Requires Authorization' }]
  const workerStatusOptions = [{ value: 'completed', label: 'Completed' }, { value: 'additional work found', label: 'Additional Work Found' }, { value: 'requires part', label: 'Requires Part' }, { value: 'requires follow-up', label: 'Requires Follow-up' }, { value: 'requires authorization', label: 'Requires Authorization' }]


  const [audioUrl, setAudioUrl] = useState('');
  const [recordingUrl, setRecordingUrl] = useState('');
  const [audioSlideUpVisible, setAudioSlideUpVisible] = useState(false);
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [csvProps, setCsvProps] = useState(null);
  const csvPropsRef = useRef(null);

  const [filters, setFilters] = useState({
    searchValue: '',
    phoneNumber: '',
    workerName: '',
    workerStatus: ''
  });

  //slide-up modal
  const [slideUpVisible, setSlideUpVisible] = useState(false);
  const [slideUpModalSize, setSlideUpModalSize] = useState([false, true, false,]);
  const [slideUpWidth, setSlideUpWidth] = useState("500");
  useEffect(() => {
    if (slideUpModalSize[0]) setSlideUpWidth("600");
    if (slideUpModalSize[1]) setSlideUpWidth("700");
    if (slideUpModalSize[2]) setSlideUpWidth("300");
  }, [slideUpModalSize]);


  // GET Actions
  useEffect(() => {
    setIsLoading(true);
    if (userId) {
      dispatch(getCorporateCheckInVisits(jwtToken, userId));
      dispatch(getWorkSitesForCorporate(jwtToken, userId));
    }
  }, [userId]);

  useEffect(() => {
    if (recordingUrl?.length > 0 && !audioSlideUpVisible) {
      const fetchRecording = async () => {
        try {
          const jwtToken = localStorage.getItem('jwtToken');
          setAudioSlideUpVisible(true);
          const url = await dispatch(getCallRecordingUrl(jwtToken, recordingUrl));
          if (url?.data) {
            const audioBlob = new Blob([url?.data], { type: 'audio/mpeg' });
            const audioObjectUrl = URL.createObjectURL(audioBlob);
            setAudioUrl(audioObjectUrl);
            setRecordingUrl('');
          } else {
            toast.error('Try again later');
            setAudioSlideUpVisible(false);
            setRecordingUrl("");
            setAudioUrl("");
            setName("");
          }
        } catch (error) {
          toast.error('Try again later');
        }
      };
      fetchRecording();
    }
  }, [recordingUrl, audioSlideUpVisible, dispatch]);

  useEffect(() => {
    if (callRecords) {
      const data = callRecords.map((record, i) => ({ ...record, index: i + 1 }));
      if (worksiteId?.length > 0) {
        const filteredData = data?.filter((record) => record?.worksiteId === worksiteId).map((record, i) => ({ ...record, index: i + 1 }));
        setCallRecordsWithIndex(filteredData);
      } else {
        setCallRecordsWithIndex(data);
      }
    }
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [callRecords, worksiteId]);

  useEffect(() => {
    if (workSitesForCorporateData) {
      setWorksiteOptionsForFilter([]);
      const worksiteData = workSitesForCorporateData?.map((record) => ({
        value: record?.id,
        label: record?.storeName,
      }));

      const uniqueArray = Array.from(new Map(worksiteData.map(item => [item.value, item])).values());
      if (uniqueArray?.length > 0) {
        uniqueArray.unshift({ value: '', label: 'All Stores' });
      }
      setWorksiteOptionsForFilter(uniqueArray);
    }
  }, [workSitesForCorporateData]);

  useEffect(() => {
    if (callRecordsErrorData && Object.keys(callRecordsErrorData)?.length > 0) {
      dispatch(resetGetCheckInCallRecordError());
      setIsLoading(false);
    }
  }, [callRecordsErrorData]);

  useEffect(()=>{
    setExportCheckInVisitsData(nullToEmpty(callRecordsWithIndex));
  },[callRecordsWithIndex]);

  const nullToEmpty = (data) => {
    return data?.map((item, i) => {
      let updatedItem = {};

      for (let key in item) {
        updatedItem[key] = item[key] === null ? "" : item[key];
        if ((key === 'checkIn' || key === 'checkOut')   && updatedItem[key]) {
          updatedItem[key] = formatDate(updatedItem[key]);
        }
      }

      return {
        ...updatedItem,
        index: i + 1
      };
    });
  }

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // 12-hour time format
    };
  
    // Format the date
    const formattedDate = date.toLocaleString('en-US', options);
    
    return formattedDate;
  };

  useEffect(() => {
    if (workSitesForCorporateError && Object.keys(workSitesForCorporateError)?.length > 0) {
      dispatch(resetWorkSiteErrorData());
    }
  }, [workSitesForCorporateError]);

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  useEffect(() => {
    const { phoneNumber, workerName, workerDob } = filters; // Extract phoneNumber and workerName filters

    let filteredData = callRecords;

    // Apply worksiteId filter if provided
    if (worksiteId?.length > 0) {
      filteredData = filteredData.filter(data => data.worksiteId === worksiteId);
    }

    if (startDateRange && endDateRange) {
      filteredData = filteredData.filter(data => {
        const createdAtDate = new Date(data.createdAt);
        return createdAtDate >= startDateRange && createdAtDate <= endDateRange;
      });
    }
    10221976
    // Apply phoneNumber filter if provided
    if (phoneNumber) {
      filteredData = filteredData.filter(data =>
        data.phoneNumber?.toLowerCase().includes(phoneNumber.toLowerCase())
      );
    }

    // Apply workerName filter if provided
    if (workerName) {
      filteredData = filteredData.filter(data =>
        data.workerName?.toLowerCase().includes(workerName.toLowerCase())
      );
    }

    // Apply phoneNumber filter if provided
    if (workerDob) {
      filteredData = filteredData.filter(data =>
        data.workerDob?.toLowerCase().includes(workerDob.toLowerCase())
      );
    }

    if (workerStatus && workerStatus !== 'all') {
      filteredData = filteredData.filter(data => data.workerStatus === workerStatus);
    }

    // Add index and update state
    setCallRecordsWithIndex(filteredData.map((record, i) => ({ ...record, index: i + 1 })));
  }, [filters, callRecords, worksiteId, workerStatus, startDateRange, endDateRange]);

  const handleDateRange = (event, picker) => {
    setStartDateRange(picker.startDate.toDate());
    setEndDateRange(picker.endDate.toDate());
  };

  // PATCH Actions
  useEffect(() => {
    if (updateCheckInCallRecordData && Object.keys(updateCheckInCallRecordData)?.length > 0) {
      setCallRecord(callRecordEmptyState);
      toast.success('Check out call record data updated successfully');
      setSlideUpVisible(false);
      dispatch(getCorporateCheckInVisits(jwtToken, userId));
      dispatch(resetUpdateCheckInCallRecordData());
    }
  }, [updateCheckInCallRecordData, userId]);

  useEffect(() => {
    if (updateCheckInCallRecordError && Object.keys(updateCheckInCallRecordError)?.length > 0) {
      toast.error(updateCheckInCallRecordError.message);
      dispatch(resetUpdateCheckInCallRecordError());
    }
  }, [updateCheckInCallRecordError]);

  const handleInputChange = (e, selectOption = null) => {
    if (selectOption) {
      setCallRecord((prevCallRecord) => ({
        ...prevCallRecord,
        corporateId: userId,
        [e.name]: selectOption
      }));
    } else {
      const { name, value } = e.target;
      setCallRecord((prevCallRecord) => ({
        ...prevCallRecord,
        corporateId: userId,
        [name]: value
      }));
    }
  };

  const handleCheckoutClick = (row) => {
    setCurrentCallRecordId(row.id)
    setSlideUpVisible(true)
    setCallRecord({
      checkOutWorkerName: row?.workerName,
      checkOutTechPhoneNo: row?.workerDob,
    });
  }

  const closeModal = () => {
    setName('');
    setAudioUrl('');
    setAudioSlideUpVisible(false);
  };

  const handleClickUrlOne = (row) => {
    if (!audioSlideUpVisible) {
      setRecordingUrl(row.checkInRecordingUrl);
      setName(row.workerName);
    }
  };

  const handleEdit = () => {
    const missingFields = [];
    if (!callRecord.checkOutWorkerName) missingFields.push('Worker name');
    if (!callRecord.checkOutTechPhoneNo) missingFields.push('Technician phone no');
    if (!callRecord.workerStatus) missingFields.push('Worker status');
    if (!callRecord.checkOut) missingFields.push('Check out');

    if (missingFields.length > 0) {
      toast.error('Please fill all required fields')
    }

    if (missingFields?.length === 0) {
      dispatch(updateCheckInCallRecord(jwtToken, currentCallRecordId, callRecord));
    }
  };


  const tableColumns = [
    // {
    //   dataField: 'index',
    //   text: '#',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    // },
    // {
    //   dataField: 'createdAt',
    //   text: 'Date',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   sort: true,
    //   formatter: (cell) => {
    //     if (cell) {
    //       return new Date(cell).toLocaleString('en-US', {
    //         year: 'numeric',
    //         month: 'numeric',
    //         day: 'numeric',
    //         // hour: '2-digit',
    //         // minute: '2-digit',
    //         // second: '2-digit',
    //         // hour12: true,
    //       });
    //     } else {
    //       return '';
    //     }
    //   },
    // },
    {
      dataField: 'workOrderNumber',
      text: 'Work Order No',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      formatter: (cell, row) => {
        return (
          <Link to={`/corporate/work-order/${row.worksiteId}/${row.workOrderNumber}`}>
            {row.workOrderNumber}
          </Link>
        );
      },
    },
    {
      dataField: 'checkIn',
      text: 'Date',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        const dateA = new Date(a).getTime();
        const dateB = new Date(b).getTime();
      
        if (order === 'asc') {
          return dateA - dateB; // Sort in ascending order
        } else {
          return dateB - dateA; // Sort in descending order
        }
      },
      formatter: (cell, row) => {
        if (cell) {
          return (
            <>
              <span>
                {new Date(cell).toLocaleString('en-US', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                })}
              </span>
            </>
          );
        } else {
          return '';
        }
      },
    },
    {
      dataField: 'checkIn',
      text: 'Check In',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        const dateA = new Date(a).getTime();
        const dateB = new Date(b).getTime();
      
        if (order === 'asc') {
          return dateA - dateB; // Sort in ascending order
        } else {
          return dateB - dateA; // Sort in descending order
        }
      },
      formatter: (cell, row) => {
        if (cell) {
          return (
            <>
              <span>
                {new Date(cell).toLocaleString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: true,
                })}
              </span>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  flexDirection: 'row',
                  cursor: 'pointer',
                  marginTop: '7px',
                }}
                onClick={() => handleClickUrlOne(row)}
              >
                <div>
                  {row?.checkInRecordingUrl && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#007bff"
                      className="bi bi-play-circle"
                      viewBox="0 0 16 16"
                      style={{ marginRight: '5px' }}
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                    </svg>
                  )}
                </div>
                <div>
                  {row?.checkInCallDuration
                    ? `${row?.checkInCallDuration} sec`
                    : ''}
                </div>
              </div>
            </>
          );
        } else {
          return '';
        }
      },
    },
    {
      dataField: 'workerName',
      text: 'Check-in Technician Name',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
    },
    {
      dataField: 'workerDob',
      text: 'Technician Phone No',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
      const phoneA = BigInt(a.replace(/\D/g, ''));
      const phoneB = BigInt(b.replace(/\D/g, ''));

      if (order === 'asc') {
        return phoneA > phoneB ? 1 : -1;
      }
      if(order === 'desc') {
        return phoneA < phoneB ? 1 : -1;
      }
    },
    },
    // {
    //   dataField: 'phoneNumber',
    //   text: 'Phone No',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   //   sort: true,
    // },
    {
      dataField: 'workSite.storeNumber',
      text: 'Store Number',
      headerStyle: { backgroundColor: '#f0f0f073' },
        sort: true,
    },
    {
      dataField: 'workSite.storeName',
      text: 'Store Name',
      headerStyle: { backgroundColor: '#f0f0f073' },
        sort: true,
    },
    {
      dataField: 'workSite.address',
      text: 'Address',
      headerStyle: { backgroundColor: '#f0f0f073' },
        sort: true,
    },
    {
      dataField: 'workSite.city',
      text: 'City',
      headerStyle: { backgroundColor: '#f0f0f073' },
        sort: true,
    },
    {
      dataField: 'workSite.state',
      text: 'State',
      headerStyle: { backgroundColor: '#f0f0f073' },
        sort: true,
    },
    {
      dataField: 'checkOut',
      csvExport: false,
      text: 'Check Out',
      headerStyle: { backgroundColor: '#f0f0f073' },
      formatter: (cell, row) => {
        return (
          <Button
            type="primary"
            utilclass="m-r-5"
            iconposition="center"
            onClick={() => handleCheckoutClick(row)}
          >
            Check out
          </Button>
        );
      },
    },

  ];

  const formateDate = (newDate) => {
    const originalDate = new Date(newDate);
    const options = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      // timeZone: 'UTC'
    };

    const formattedDate = originalDate.toLocaleString('en-US', options)
      .replace(',', '');
    return formattedDate;
  }

  return (
    <div className="page-container">
      <div className="page-content-wrapper">
        <div className="content">
          <PageContainer className="container-fluid container-fixed-lg bg-white">
            <div className="card card-transparent">
              <div className="card-header d-flex flex-column justify-content-between ">
                <div className="d-flex flex-row justify-content-between mb-2">
                  <h3 className="text-primary no-margin text-uppercase">Check-In Visits</h3>
                  <div className='row gap-2 px-3'>
                    <div className="pl-2">
                      {csvProps && (
                        <ExportCSVButton {...csvProps}><i className="pg-icon m-r-10"><FiShare size={'15'} /></i> Export CSV</ExportCSVButton>
                      )}
                    </div>
                  </div>
                </div>
                <div className="export-options-container">
                  <div className="exportOptions">
                    {slideUpVisible ?
                      <SlideUpModal visible={slideUpVisible} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisible(false)}>
                        <div className="modal-content-wrapper">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5>Checkout Call Record</h5>
                              <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisible(false)} data-dismiss="modal" aria-hidden="true">
                                <i className="pg-icon">close</i>
                              </button>
                            </div>
                            <div className="modal-body p-5">
                              <form role="form">
                                <div class="input-group flex-column">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <label for='workerName' className='mt-3'>Worker Name<span style={{ color: 'red' }}>*</span></label>
                                      <input type="text" className="form-control w-100" placeholder='Update Worker Name' value={callRecord.checkOutWorkerName} onChange={handleInputChange} name='checkOutWorkerName' id='workerName' />
                                    </div>
                                    <div className="col-md-4">
                                      <label for='workerDob' className='mt-3'>Technician Phone No<span style={{ color: 'red' }}>*</span></label>
                                      <input type="text" className="form-control w-100" placeholder='Update Technician Phone No' value={callRecord.checkOutTechPhoneNo} disabled name='checkOutTechPhoneNo' id='workerDob' />
                                    </div>

                                    <div className="col-md-4">
                                      <label htmlFor='workerStatus' className='mt-3'>Worker Status<span style={{ color: 'red' }}>*</span></label>
                                      <GroupSelect
                                        inputId='workerStatus'
                                        value={callRecord.workerStatus}
                                        options={workerStatusOptions}
                                        onChange={(selectedOption) => handleInputChange({ name: 'workerStatus' }, selectedOption?.value)}
                                      />
                                    </div>
                                  </div>

                                  <label for='checkOut' className='mt-3'>Check Out<span style={{ color: 'red' }}>*</span></label>
                                  <DatePicker
                                    onChange={(selectedOption) => handleInputChange({ name: 'checkOut' }, selectedOption)}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    timeCaption="Time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    placeholderText="Select date and time"
                                    className="form-control w-100"
                                    selected={callRecord.checkOut}
                                    name='checkOut'
                                    id='checkOut'
                                  />
                                  <button aria-label="" type="button" className="btn btn-primary mt-3" onClick={handleEdit}>
                                    Update
                                  </button>
                                </div>

                              </form>
                            </div>
                          </div>
                        </div>
                      </SlideUpModal>
                      : null
                    }

                    {audioSlideUpVisible ? (
                      <SlideUpModal visible={audioSlideUpVisible} width={'400'} effect="fadeInUp" onClickAway={() => closeModal()}>
                        <div className="modal-content-wrapper">
                          <div className="modal-content">
                            <div className="modal-top">
                              <button
                                aria-label=""
                                type="button"
                                className="close"
                                onClick={() => closeModal()}
                                aria-hidden="true"
                              >
                                <i className="pg-icon">close</i>
                              </button>
                              <h4 className="text-primary no-margin text-uppercase text-center">{name}</h4>
                            </div>
                            {audioUrl?.length > 0 ? (
                              <div className="modal-body">
                                <form role="form">
                                  <div className="form-group-attached">
                                    <div className="row justify-content-center">
                                      <audio controls style={{ margin: "0", height: '40px' }}>
                                        <source src={audioUrl} type="audio/mpeg" />
                                      </audio>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            ) : (
                              <div className="modal-body">
                                <div className="row justify-content-center">
                                  <div class="spinner-grow" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </SlideUpModal>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className='d-flex flex-row justify-content-start'>
                <div className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0 px-1 p-l-0">
                  <div className="input-group">
                    <span className="input-group-text input-date-icon-pre">
                      <i className="pg-icon">search</i>
                    </span>
                    <input
                      type="text"
                      name="workerName"
                      className="form-control py-2"
                      placeholder="Search by check-in technician name"
                      value={filters.workerName}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0 px-1">
                  <div className="input-group">
                    <span className="input-group-text input-date-icon-pre">
                      <i className="pg-icon">search</i>
                    </span>
                    <input
                      type="text"
                      className="form-control py-2"
                      placeholder="Search by technician phone number"
                      name='workerDob'
                      value={filters.workerDob}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>

                {/* <div className="col-12 col-md-6 col-lg-2 mb-3 mb-lg-0 px-1">
                  <div className="input-group m-r-15">
                    <GroupSelect
                      placeholder='Status'
                      inputId='worksiteId'
                      value={workerStatus}
                      options={workerStatusFilterOptions}
                      onChange={(selectedOption) => setWorkerStatus(selectedOption?.value)}
                    />
                  </div>
                </div> */}
                <div className="col-12 col-md-6 col-lg-2 mb-3 mb-lg-0 px-1">
                  <GroupSelect
                    placeholder='Store Name'
                    inputId="worksiteId"
                    value={worksiteId}
                    options={worksiteOptionsForFilter}
                    onChange={(selectedOption) => setWorksiteId(selectedOption?.value)}
                  />
                </div>

                <div className="col-12 col-md-6 col-lg-3 mb-3 mb-md-0 px-1">
                  <DateRangePicker
                    startDate={startDateRange}
                    endDate={endDateRange}
                    showTimeSelect
                    onApply={handleDateRange}
                  >
                    <div className="input-group col-md-19">
                      <span className="input-group-text input-date-icon-pre">
                        <i className="pg-icon">calendar</i>
                      </span>
                      <input
                        type="text"
                        name="reservation"
                        id="daterangepicker"
                        className="form-control py-2"
                        value={`${moment(startDateRange).format("MM/DD/YYYY h:mm A")} - ${moment(endDateRange).format("MM/DD/YYYY h:mm A")}`}
                        onChange={() => { }}
                      />
                    </div>
                  </DateRangePicker>
                </div>
              </div>

              {!isLoading ? <div className="card-body custable">
                {callRecordsWithIndex?.length > 0 ? (
                  <ToolkitProvider
                    keyField="id"
                    data={exportCheckInVistsData}
                    columns={tableColumns}
                    exportCSV={{
                      fileName: 'callRecords-data.csv',
                      ignoreHeader: false,
                      noAutoBOM: false,
                    }}
                  >
                    {(props) => {
                      csvPropsRef.current = props.csvProps;
                      if (!csvProps) {
                        setCsvProps(props.csvProps);
                      }
                      return (
                        <React.Fragment>
                          <BootstrapTable
                            {...props.baseProps}
                            hover={true}
                            bootstrap4
                            bordered={false}
                            condensed={true}
                            striped={true}
                            pagination={paginationFactory()}
                          // cellEdit={cellEditFactory({
                          //   mode: 'click',
                          //   blurToSave: true,
                          // })}
                          />
                        </React.Fragment>
                      )
                    }}
                  </ToolkitProvider>
                ) : (
                  <NoData />
                )}
              </div> : <Loader />}
            </div>
          </PageContainer>
        </div>
      </div>
    </div>
  );
};

export default CallRecords;
