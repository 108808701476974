import React, { useEffect, useState } from 'react';
import PageContainer from '../UIElements/Containers';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import { useDispatch, useSelector } from 'react-redux';
import { resetCorporateRecordsErrorData, resetEditCorporateRecordData, resetGetCorporateRecordErrorData } from '../../api-actions/corporateDataAction';
import { getSingleWorksite, editWorkSite, resetEditWorkSiteData, resetEditWorkSiteErrorData } from '../../api-actions/workSiteDataAction';
import Button from '../UIElements/Button/UtilsButton';
import SlideUpModal from '../Modals/SlideUpModal';
import { toast } from 'react-toastify';
import GroupSelect from '../Forms/Elements/GroupSelect';
import { useNavigate } from 'react-router-dom';
import { handleLogout } from '../../utils/logout';
import { editPassword, resetEditPasswordData, resetEditPasswordErrorData } from '../../api-actions/workSiteDataAction';
import InputWithLabel from '../Forms/Elements/InputWithLabel';

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jwtToken = localStorage.getItem('jwtToken');
  const workSiteEmptyState = { storeNumber: '', storeName: '', address: '', address2: '', city: '', state: '', zip: '', country: '', timeZone: '', managerName: '', managerEmail: '' };
  const worksiteUpdatePasswordEmptyState = { worksitePassword: '', worksiteNewPassword: '' };

  const userType = useSelector((state) => state.AdminAuthData.userType);
  const worksiteId = useSelector((state) => state.AdminAuthData.userId);
  const worksiteManagerData = useSelector((state) => state.WorkSiteData.worksiteManagerData);
  const worksiteManagerError = useSelector((state) => state.WorkSiteData.worksiteManagerError);
  const editCorporateRecordData = useSelector((state) => state.WorkSiteData.editWorkSiteData);
  const editCorporateRecordError = useSelector((state) => state.WorkSiteData.editWorkSiteError);
  const getTollfreeNumber = useSelector((state) => state.WorkSiteData.getTollfreeNumber);
  const editWorksitePasswordData = useSelector((state) => state.WorkSiteData.editWorksitePasswordData);
  const editWorksitePasswordError = useSelector((state) => state.WorkSiteData.editWorksitePasswordError);

  const [worksiteRecord, setWorksiteRecord] = useState(workSiteEmptyState);
  const [worksiteUpdatePassword, setWorksiteUpdatePassword] = useState(worksiteUpdatePasswordEmptyState);
  const [worksiteConfirmPassword, setWorksiteConfirmPassword] = useState('');

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (worksiteId) {
      dispatch(getSingleWorksite(jwtToken, worksiteId));
    }
  }, [worksiteId]);

  useEffect(() => {
    if (worksiteManagerError && Object.keys(worksiteManagerError)?.length > 0) {
      dispatch(resetGetCorporateRecordErrorData());
    }
  }, [worksiteManagerError]);

  useEffect(() => {
    if (editCorporateRecordData && Object.keys(editCorporateRecordData)?.length > 0) {
      setWorksiteRecord(workSiteEmptyState);
      toast.success('Corporate record updated successfully');
      setSlideUpVisible(false);
      dispatch(getSingleWorksite(jwtToken, worksiteId));
      dispatch(resetEditWorkSiteData());
      localStorage.setItem('adminName', worksiteRecord.managerName);
    }
  }, [editCorporateRecordData]);

  useEffect(() => {
    if (editCorporateRecordError && Object.keys(editCorporateRecordError)?.length > 0) {
      toast.error(editCorporateRecordError.message);
      dispatch(resetEditWorkSiteErrorData());
    }
  }, [editCorporateRecordError]);

  useEffect(() => {
    if (editWorksitePasswordData && Object.keys(editWorksitePasswordData)?.length > 0) {
      setWorksiteUpdatePassword(worksiteUpdatePasswordEmptyState);
      handleLogout(dispatch, navigate, 'Worksite password updated successfully, you will logout soon. Please login again!');
      dispatch(resetEditPasswordData());
    }
  }, [editWorksitePasswordData]);

  useEffect(() => {
    if (editWorksitePasswordError && Object.keys(editWorksitePasswordError)?.length > 0) {
      toast.error(editWorksitePasswordError.message);
      dispatch(resetEditPasswordErrorData());
    }
  }, [editWorksitePasswordError]);

  //slide-up modal
  const [slideUpVisible, setSlideUpVisible] = useState(false);
  const [slideUpVisibleForDelete, setSlideUpVisibleForDelete] = useState(false);
  const [slideUpModalSize, setSlideUpModalSize] = useState([false, true, false]);
  const [slideUpWidth, setSlideUpWidth] = useState("500");
  useEffect(() => {
    if (slideUpModalSize[0]) setSlideUpWidth("600");
    if (slideUpModalSize[1]) setSlideUpWidth("700");
    if (slideUpModalSize[2]) setSlideUpWidth("300");
  }, [slideUpModalSize]);

  const handleInputChange = (e, selectOption = null) => {
    if (selectOption) {
      setWorksiteRecord((prevWorkSite) => ({
        ...prevWorkSite,
        [e.name]: selectOption.value,
      }));
    } else {
      const { name, value } = e.target;
      setWorksiteRecord((prevWorkSite) => ({
        ...prevWorkSite,
        [name]: value,
      }));
    }
  }

  const handleEditClick = () => {
    setSlideUpVisible(true)
    setWorksiteRecord({
      storeNumber: worksiteManagerData.storeNumber,
      storeName: worksiteManagerData.storeName,
      address: worksiteManagerData.address,
      address2: worksiteManagerData.address2,
      city: worksiteManagerData.city,
      state: worksiteManagerData.state,
      zip: worksiteManagerData.zip,
      country: worksiteManagerData.country,
      timeZone: worksiteManagerData.timeZone,
      managerName: worksiteManagerData.managerName,
      managerEmail: worksiteManagerData.managerEmail,
    })
  }

  const handleEdit = () => {
    dispatch(editWorkSite(jwtToken, worksiteId, worksiteRecord));
  }

  const handleInputChangeForUpdatePassword = (e) => {
    const { name, value } = e.target;
    setWorksiteUpdatePassword((prevWorksiteData) => ({
      ...prevWorksiteData,
      [name]: value
    }));
  }

  const handleEditForUpdatePassword = () => {
    if (worksiteUpdatePassword.worksiteNewPassword !== worksiteConfirmPassword) {
      toast.error('New password and confirm password do not match');
      return;
    }
    dispatch(editPassword(jwtToken, worksiteId, worksiteUpdatePassword));
  }

  function formatTollFreeNumber(phoneNumber) {
    // Remove any non-digit characters
    const cleaned = phoneNumber.replace(/\D/g, '');
    return `+${cleaned[0]} (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7)}`;
  }

  const countriesList = [
    { label: 'Canada', value: 'Canada' },
    { label: 'United States', value: 'United States' },
    { label: 'Mexico', value: 'Mexico' },
    { label: 'Guatemala', value: 'Guatemala' },
    { label: 'Belize', value: 'Belize' },
    { label: 'El Salvador', value: 'El Salvador' },
    { label: 'Honduras', value: 'Honduras' },
    { label: 'Nicaragua', value: 'Nicaragua' },
    { label: 'Costa Rica', value: 'Costa Rica' },
    { label: 'Panama', value: 'Panama' },
    { label: 'Cuba', value: 'Cuba' },
    { label: 'Jamaica', value: 'Jamaica' },
    { label: 'Haiti', value: 'Haiti' },
    { label: 'Dominican Republic', value: 'Dominican Republic' },
    { label: 'Bahamas', value: 'Bahamas' },
    { label: 'Barbados', value: 'Barbados' },
    { label: 'Saint Lucia', value: 'Saint Lucia' },
    { label: 'Saint Vincent and the Grenadines', value: 'Saint Vincent and the Grenadines' },
    { label: 'Grenada', value: 'Grenada' },
    { label: 'Trinidad and Tobago', value: 'Trinidad and Tobago' },
  ]

  const timeZoneList = [
    { label: 'Eastern Time (US & Canada) (UTC-05:00)', value: 'America/New_York' },
    { label: 'Eastern Time (Detroit) (UTC-05:00)', value: 'America/Detroit' },
    { label: 'Eastern Time (Toronto) (UTC-05:00)', value: 'America/Toronto' },
    { label: 'Central Time (US & Canada) (UTC-06:00)', value: 'America/Chicago' },
    { label: 'Central Time (Guadalajara) (UTC-06:00)', value: 'America/Guadalajara' },
    { label: 'Central Time (Mexico City) (UTC-06:00)', value: 'America/Mexico_City' },
    { label: 'Mountain Time (US & Canada) (UTC-07:00)', value: 'America/Denver' },
    { label: 'Mountain Time (Edmonton) (UTC-07:00)', value: 'America/Edmonton' },
    { label: 'Pacific Time (US & Canada) (UTC-08:00)', value: 'America/Los_Angeles' },
    { label: 'Pacific Time (Vancouver) (UTC-08:00)', value: 'America/Vancouver' },
    { label: 'Atlantic Time (Halifax) (UTC-04:00)', value: 'America/Halifax' },
    { label: 'Uruguay Time (Montevideo) (UTC-03:00)', value: 'America/Montevideo' },
    { label: 'Colombia Time (Bogota) (UTC-05:00)', value: 'America/Bogota' },
    { label: 'Peru Time (Lima) (UTC-05:00)', value: 'America/Lima' }
  ];

  return (
    <div className="page-container">
      <div className="page-content-wrapper">
        <div className="content">
          <PageContainer>
            <Card type={"transparent"}>
              <div className="row m-auto w-100">
                <div className="col-lg-6">
                  <Card className="p-4">
                    <CardBody className="p-0">
                      {userType === 'worksite' ? (
                        <>
                          <h3 className="text-primary no-margin text-uppercase">Settings</h3>
                          <div className='d-flex align-items-center justify-content-between'>
                            <p className='text-capitalize mt-3'><b>Name:</b> {worksiteManagerData?.managerName}</p>
                            <Button type="primary" utilclass="m-r-5" iconposition="center" onClick={handleEditClick}>
                              <i className="pg-icon">edit</i>
                            </Button>
                          </div>
                          <p><b>Email:</b> {worksiteManagerData?.managerEmail}</p>
                          <p><b>Store Name:</b> {worksiteManagerData?.storeName}</p>
                          <p><b>Store Number:</b> {worksiteManagerData?.storeNumber}</p>
                          <p><b>Address:</b> {worksiteManagerData?.address}</p>
                          <p><b>Address 2:</b> {worksiteManagerData?.address2}</p>
                          <p><b>City:</b> {worksiteManagerData?.city}</p>
                          <p><b>State:</b> {worksiteManagerData?.state}</p>
                          <p><b>Zip:</b> {worksiteManagerData?.zip}</p>
                          <p><b>Country:</b> {worksiteManagerData?.country}</p>
                          {/* <p><b>Time Zone:</b> {worksiteManagerData?.timeZone}</p> */}
                        </>
                      ) : (
                        <p className='mb-0 text-center'>No Data</p>
                      )}
                    </CardBody>
                  </Card>
                  {slideUpVisible ?
                    <SlideUpModal visible={slideUpVisible} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisible(false)}>
                      <div className="modal-content-wrapper">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5>{'Edit Worksite Record'}</h5>
                            <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisible(false)} data-dismiss="modal" aria-hidden="true">
                              <i className="pg-icon">close</i>
                            </button>
                          </div>
                          <div className="modal-body px-5 py-5">
                            <form role="form">
                              <div class="input-group flex-column">
                                <div className="row">
                                  <div className="col-md-6">
                                    <label for='managerName'>Manager Name</label>
                                    <input type="text" className="form-control w-100" placeholder={'Update Manager Name'} value={worksiteRecord.managerName} onChange={handleInputChange} name='managerName' id='managerName' />
                                  </div>
                                  <div className="col-md-6">
                                    <label for='managerEmail'>Manager Email</label>
                                    <input disabled type="text" className="form-control w-100" placeholder={'Update Manager Email'} value={worksiteRecord.managerEmail} onChange={handleInputChange} name='managerEmail' id='managerEmail' />
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-6 mt-3">
                                    <label for='storeNumber'>Store Number</label>
                                    <input type="text" className="form-control w-100" placeholder={'Update Store Number'} value={worksiteRecord.storeNumber} onChange={handleInputChange} name='storeNumber' id='storeNumber' />
                                  </div>

                                  <div className="col-md-6 mt-3">
                                    <label for='storeName'>Store Name</label>
                                    <input type="text" className="form-control w-100" placeholder={'Update Store Name'} value={worksiteRecord.storeName} onChange={handleInputChange} name='storeName' id='storeName' />
                                  </div>
                                </div>

                                <div className="row">
                                <div className="col-md-6">
                                <label for='address' className='mt-3'>Address</label>
                                <input type="text" className="form-control w-100" placeholder={'Update Address'} value={worksiteRecord.address} onChange={handleInputChange} name='address' id='address' />
                                </div>

                                <div className="col-md-6">
                                <label for='address2' className='mt-3'>Address 2</label>
                                <input type="text" className="form-control w-100" placeholder={'Update Address'} value={worksiteRecord.address2} onChange={handleInputChange} name='address2' id='address2' />
                                </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-6">
                                    <label for='city' className='mt-3'>City</label>
                                    <input type="text" className="form-control w-100" placeholder={'Update City'} value={worksiteRecord.city} onChange={handleInputChange} name='city' id='city' />
                                  </div>


                                  <div className="col-md-6">
                                    <label for='state' className='mt-3'>State</label>
                                    <input type="text" className="form-control w-100" placeholder={'Update State'} value={worksiteRecord.state} onChange={handleInputChange} name='state' id='state' />
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-3">
                                    <label for='zip' className='mt-3'>Zip</label>
                                    <input type="text" className="form-control w-100" placeholder={'Update Zip'} value={worksiteRecord.zip} onChange={handleInputChange} name='zip' id='zip' />
                                  </div>

                                  <div className="col-md-9">
                                    <label for='country' className='mt-3'>Country</label>
                                    <GroupSelect
                                      inputId='country'
                                      value={worksiteRecord.country}
                                      options={countriesList}
                                      onChange={(selectedOption) => handleInputChange({ name: 'country' }, selectedOption)}
                                    />
                                  </div>
                                </div>

                                {/* <label for='timeZone' className='mt-3'>Time Zone</label>
                                <GroupSelect
                                  inputId='timeZone'
                                  value={worksiteRecord.timeZone}
                                  options={timeZoneList}
                                  onChange={(selectedOption) => handleInputChange({ name: 'timeZone' }, selectedOption)}
                                /> */}
                              </div>
                            </form>
                          </div>

                          <div className='modal-footer'>
                            <button aria-label="" type="button" className="btn btn-primary py-2 px-3" onClick={handleEdit}>
                              {'Update'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </SlideUpModal>
                    : null}
                </div>
                <div className="col-lg-6">
                  <Card className="p-4">
                    <CardBody className="p-0">
                      <h3 className="text-primary no-margin text-uppercase">Instructions</h3>
                      <p className='mt-3'>To register your number:</p>
                      <p><b>Step 1:</b> Call <span className='text-success'>{getTollfreeNumber.tollFreeNumber && formatTollFreeNumber(getTollfreeNumber.tollFreeNumber)}</span></p>
                      <p><b>Step 2:</b> Press 8</p>
                      <p><b>Step 3:</b> Enter site pin - <span className='text-success'>{worksiteManagerData?.worksitePin}</span></p>
                      <p>Your new number will be verified.</p>
                    </CardBody>
                  </Card>

                  <Card className="p-4">
                    <CardBody className="p-0">
                      {userType === 'worksite' ? (
                        <>
                          <h3 className="text-primary no-margin text-uppercase">Update Password</h3>
                          <div className='d-flex flex-column mt-3'>
                            <div className="form-group form-group-default required py-2 d-flex align-items-center justify-content-between">
                              <div style={{ flexGrow: 1 }}>
                                <InputWithLabel
                                  className="form-control"
                                  placeholder="Enter Old Password"
                                  name="worksitePassword"
                                  value={worksiteUpdatePassword.worksitePassword}
                                  onChange={handleInputChangeForUpdatePassword}
                                  type={showOldPassword ? "text" : "password"}
                                />
                              </div>
                              <div
                                onClick={() => setShowOldPassword(!showOldPassword)}
                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                              >
                                {showOldPassword ? (
                                  <i className="pg-icon" style={{ cursor: 'pointer' }}>eye</i>
                                ) : (
                                  <i className="pg-icon" style={{ cursor: 'pointer' }}>eye_off</i>
                                )}
                              </div>
                            </div>

                            <div className="form-group form-group-default required py-2 d-flex align-items-center justify-content-between mt-1">
                              <div style={{ flexGrow: 1 }}>
                                <InputWithLabel
                                  className="form-control"
                                  placeholder="Enter New Password"
                                  name="worksiteNewPassword"
                                  value={worksiteUpdatePassword.worksiteNewPassword}
                                  onChange={handleInputChangeForUpdatePassword}
                                  type={showNewPassword ? "text" : "password"}
                                />
                              </div>
                              <div
                                onClick={() => setShowNewPassword(!showNewPassword)}
                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                              >
                                {showNewPassword ? (
                                  <i className="pg-icon" style={{ cursor: 'pointer' }}>eye</i>
                                ) : (
                                  <i className="pg-icon" style={{ cursor: 'pointer' }}>eye_off</i>
                                )}
                              </div>
                            </div>

                            <div className="form-group form-group-default required py-2 d-flex align-items-center justify-content-between mt-1">
                              <div style={{ flexGrow: 1 }}>
                                <InputWithLabel
                                  className="form-control"
                                  placeholder="Confirm New Password"
                                  value={worksiteConfirmPassword}
                                  onChange={(e) => setWorksiteConfirmPassword(e.target.value)}
                                  type={showConfirmPassword ? "text" : "password"}
                                />
                              </div>
                              <div
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                              >
                                {showConfirmPassword ? (
                                  <i className="pg-icon" style={{ cursor: 'pointer' }}>eye</i>
                                ) : (
                                  <i className="pg-icon" style={{ cursor: 'pointer' }}>eye_off</i>
                                )}
                              </div>
                            </div>

                            <button aria-label="" type="button" className="btn btn-primary py-2 px-3 mt-2" onClick={handleEditForUpdatePassword}>
                              Update Password
                            </button>
                          </div>
                        </>
                      ) : (
                        <p className='mb-0 text-center'>No Data</p>
                      )}
                    </CardBody>
                  </Card>
                </div>
              </div>
            </Card>
          </PageContainer>
        </div>
      </div>
    </div>
  );
};

export default Settings;
