import React, { useState, useEffect, useRef } from 'react';
import ToolkitProvider, {
  CSVExport,
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';

import Button from '../UIElements/Button/UtilsButton';
import PageContainer from '../UIElements/Containers';
import SlideUpModal from '../Modals/SlideUpModal';
import { toast } from 'react-toastify';
import GroupSelect from "../Forms/Elements/GroupSelect";
import {
  getWorksiteCallRecords,
  resetGetCallRecordsByWorkOrderNumberData,
  resetGetCallRecordsByWorkOrderNumberError,
  getCallRecordingUrl,
  getCallRecordsByWorkOrderNumber,
  getSingleWorksite,
  editCallRecordByWorksite,
  deleteCallRecordByWorksite,
  resetEditCallRecordData,
  resetEditCallRecordErrorData,
  resetDeleteCallRecordData,
  resetDeleteCallRecordErrorData,
} from '../../api-actions/workSiteDataAction';
import { useDispatch, useSelector } from 'react-redux';
import { FiShare } from 'react-icons/fi';

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router-dom';
import { calculateTotalWorkHours, calculateTotalWorkers, calculateTotalCheckIns, calculateTotalCheckOuts } from '../../utils/calculateTotal';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import { RiShareBoxFill } from 'react-icons/ri';
import Loader from '../Loader/Loader';
import NoData from '../NoData/NoData';
import { nullToEmpty } from '../../utils/csvExport';

const CallRecordsByWorkOrderNumber = () => {
  const { ExportCSVButton } = CSVExport;
  const jwtToken = localStorage.getItem('jwtToken');
  const workerStatusOptions = [{ value: 'completed', label: 'Completed' }, { value: 'additional work found', label: 'Additional Work Found' }, { value: 'requires part', label: 'Requires Part' }, { value: 'requires follow-up', label: 'Requires Follow-up' }, { value: 'requires authorization', label: 'Requires Authorization' }]
  const callRecordEmptyState = {
    phoneNumber: '',
    workerName: '',
    workerDob: '',
    workerStatus: '',
    workOrderNumber: '',
    checkIn: '',
    checkOut: '',
  };

  let endDate = new Date();
  endDate.setDate(endDate.getDate() + 30);

  const dispatch = useDispatch();
  const url = useParams();

  const value = url['*'];
  const workOrderNumber = value.split('/').pop();

  const worksiteId = useSelector((state) => state.AdminAuthData.userId);
  const callRecords = useSelector((state) => state.WorkSiteData.getCallRecordsByWorkOrderNumberData);
  const callRecordsErrorData = useSelector((state) => state.WorkSiteData.getCallRecordsByWorkOrderNumberError);
  const worksiteManagerData = useSelector((state) => state.WorkSiteData.worksiteManagerData);
  const worksiteManagerError = useSelector((state) => state.WorkSiteData.worksiteManagerError);
  const editCallRecordData = useSelector((state) => state.WorkSiteData.editCallRecordData);
  const editCallRecordError = useSelector((state) => state.WorkSiteData.editCallRecordError);
  const deleteCallRecordData = useSelector((state) => state.WorkSiteData.deleteCallRecordData);
  const deleteCallRecordError = useSelector((state) => state.WorkSiteData.deleteCallRecordError);


  const [callRecordsWithIndex, setCallRecordsWithIndex] = useState([]);
  const [searchValue, setSearchValue] = useState({ name: '', phone: '' });
  const [startDateRange, setStartDateRange] = useState();
  const [endDateRange, setEndDateRange] = useState();
  const [worksiteOptionsForFilter, setWorksiteOptionsForFilter] = useState([]);
  const [workerStatus, setWorkerStatus] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [currentCallRecordId, setCurrentCallRecordId] = useState(null);
  const [callRecord, setCallRecord] = useState(callRecordEmptyState)
  const [audioUrl, setAudioUrl] = useState('');
  const [recordingUrl, setRecordingUrl] = useState('');
  const [audioSlideUpVisible, setAudioSlideUpVisible] = useState(false);
  const [name, setName] = useState("");
  const [viewData, setViewData] = useState(false);
  const [slideUpVisibleForViewData, setSlideUpVisibleForViewData] = useState(false);
  const [exportCallRecordsData, setExportCallRecordsData] = useState()

  //slide-up modal
  const [slideUpVisible, setSlideUpVisible] = useState(false);
  const [slideUpVisibleForDelete, setSlideUpVisibleForDelete] = useState(false);
  const [slideUpModalSize, setSlideUpModalSize] = useState([false, true, false,]);
  const [slideUpWidth, setSlideUpWidth] = useState("500");

  const [csvProps, setCsvProps] = useState(null);
  const csvPropsRef = useRef(null);

  const [filters, setFilters] = useState({
    phoneNumber: '',
    workerName: '',
    workerStatus: ''
  });

  // Callback to get CSV props from ToolkitProvider
  // useEffect(() => {
  //   if (csvPropsRef.current) {
  //     setCsvProps(csvPropsRef.current);
  //   }
  // }, [csvPropsRef.current]);



  // GET Actions
  useEffect(() => {
    setIsLoading(true)
    if (worksiteId) {
      dispatch(getCallRecordsByWorkOrderNumber(jwtToken, worksiteId, workOrderNumber));
      dispatch(getSingleWorksite(jwtToken, worksiteId));
    }
    return () => {
      dispatch(resetGetCallRecordsByWorkOrderNumberData())
    }
  }, [worksiteId]);

  useEffect(() => {
    if (recordingUrl?.length > 0 && !audioSlideUpVisible) {
      const fetchRecording = async () => {
        try {
          const jwtToken = localStorage.getItem('jwtToken');
          setAudioSlideUpVisible(true);
          const url = await dispatch(getCallRecordingUrl(jwtToken, recordingUrl));
          if (url?.data) {
            const audioBlob = new Blob([url?.data], { type: 'audio/mpeg' });
            const audioObjectUrl = URL.createObjectURL(audioBlob);
            setAudioUrl(audioObjectUrl);
            setRecordingUrl('');
          } else {
            toast.error('Try again later');
            setAudioSlideUpVisible(false);
            setRecordingUrl("");
            setAudioUrl("");
            setName("");
          }
        } catch (error) {
          // toast.error('Try again later');
        }
      };
      fetchRecording();
    }
  }, [recordingUrl, audioSlideUpVisible, dispatch]);

  useEffect(() => {
    if (callRecords.length > 0) {
      const data = callRecords.map((record, i) => ({ ...record, index: i + 1 }))
      if (workerStatus?.length > 0) {
        const filteredData = data?.filter((record) => record?.workerStatus === workerStatus).map((record, i) => ({ ...record, index: i + 1 }));
        setCallRecordsWithIndex(filteredData);
      } else {
        setCallRecordsWithIndex(data)
      }
    }
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [callRecords, workerStatus]);

  useEffect(() => {
    if (callRecordsErrorData && Object.keys(callRecordsErrorData)?.length > 0) {
      dispatch(resetGetCallRecordsByWorkOrderNumberError());
      setIsLoading(false)
    }
  }, [callRecordsErrorData]);

  useEffect(() => {
    if (worksiteManagerError && Object.keys(worksiteManagerError)?.length > 0) {
      toast.error(worksiteManagerError.message);
      dispatch(resetGetSingleWorksiteError());
    }
  }, [worksiteManagerError]);

  // PATCH Actions
  useEffect(() => {
    if (editCallRecordData && Object.keys(editCallRecordData)?.length > 0) {
      setCallRecord(callRecordEmptyState);
      toast.success('Call record data updated successfully');
      setSlideUpVisible(false);
      dispatch(getCallRecordsByWorkOrderNumber(jwtToken, worksiteId, workOrderNumber));
      dispatch(resetEditCallRecordData());
    }
  }, [editCallRecordData]);

  useEffect(() => {
    if (editCallRecordError && Object.keys(editCallRecordError)?.length > 0) {
      toast.error(editCallRecordError.message);
      dispatch(resetEditCallRecordErrorData());
    }
  }, [editCallRecordError]);

  useEffect(() => {
    if (deleteCallRecordData && Object.keys(deleteCallRecordData)?.length > 0) {
      toast.success('Call record data deleted successfully');
      dispatch(getCallRecordsByWorkOrderNumber(jwtToken, worksiteId, workOrderNumber));
      dispatch(resetDeleteCallRecordData());
    }
  }, [deleteCallRecordData]);

  useEffect(() => {
    if (deleteCallRecordError && Object.keys(deleteCallRecordError)?.length > 0) {
      toast.error(deleteCallRecordError.message);
      dispatch(resetDeleteCallRecordErrorData());
    }
  }, [deleteCallRecordError]);

  useEffect(() => {
    if (slideUpModalSize[0]) setSlideUpWidth("600");
    if (slideUpModalSize[1]) setSlideUpWidth("700");
    if (slideUpModalSize[2]) setSlideUpWidth("300");
  }, [slideUpModalSize]);

  useEffect(() => {
    const capitalize = (str) => {
      return str.replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const workerStatusOptions = [
      { value: 'completed', label: capitalize('Completed') },
      { value: 'additional work found', label: capitalize('Additional Work Found') },
      { value: 'requires part', label: capitalize('Requires Part') },
      { value: 'requires follow-up', label: capitalize('Requires Follow-up') },
      { value: 'requires authorization', label: capitalize('Requires Authorization') },
    ];

    let dynamicWorksiteData = [];
    if (callRecords.length > 0) {
      dynamicWorksiteData = callRecords
        .map((record) => ({
          value: record.workerStatus,
          label: capitalize(record.workerStatus),
        }))
        .filter(
          (option, index, self) =>
            index === self.findIndex((o) => o.value === option.value)
        );
    }

    const mergedOptions = [...workerStatusOptions, ...dynamicWorksiteData];
    const uniqueArray = Array.from(new Map(mergedOptions.map(item => [item.value, item])).values());
    uniqueArray.unshift({ value: '', label: 'All Status' });
    setWorksiteOptionsForFilter(uniqueArray);
  }, [callRecords]);

  const handleEditClick = (row) => {
    setCurrentCallRecordId(row.id)
    setSlideUpVisible(true)
    setCallRecord({
      worksiteId: row?.worksiteId,
      phoneNumber: row?.phoneNumber,
      workerName: row?.workerName,
      workerDob: row?.workerDob,
      workerStatus: row?.workerStatus,
      workOrderNumber: row?.workOrderNumber,
      checkIn: row?.checkIn ? new Date(row?.checkIn) : '',
      checkOut: row?.checkOut ? new Date(row?.checkOut) : '',
    });
  }

  const handleDeleteClick = (row) => {
    setCurrentCallRecordId(row.id)
    setSlideUpVisibleForDelete(true)
  }

  const handleEdit = () => {
    const missingFields = [];
    if (!callRecord.phoneNumber) missingFields.push('Phone number');
    if (!callRecord.workerName) missingFields.push('Worker name');
    if (!callRecord.workerDob) missingFields.push('Technician phone no');
    if (!callRecord.workOrderNumber) missingFields.push('Work order number');
    if (!callRecord.checkIn) missingFields.push('Check in');
    if (!callRecord.workerStatus) missingFields.push('Worker status');
    if (!callRecord.checkOut) missingFields.push('Check out');

    if (missingFields.length > 0) {
      toast.error('Please fill all required fields')
    }

    if (missingFields?.length === 0) {
      dispatch(editCallRecordByWorksite(jwtToken, currentCallRecordId, callRecord));
    }
  };

  const handleDelete = () => {
    if (currentCallRecordId) {
      dispatch(deleteCallRecordByWorksite(jwtToken, currentCallRecordId));
      setSlideUpVisibleForDelete(false);
    }
  };

  const handleClickUrlOne = (row) => {
    if (!audioSlideUpVisible) {
      setRecordingUrl(row.checkInRecordingUrl);
      setName(row.workerName);
    }
  };

  const handleClickUrlTwo = (row) => {
    if (!audioSlideUpVisible) {
      setRecordingUrl(row.checkOutRecordingUrl);
      setName(row.workerName);
    }
  };

  const formateDate = (newDate) => {
    const originalDate = new Date(newDate);
    const options = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      // timeZone: 'UTC'
    };
    const formattedDate = originalDate.toLocaleString('en-US', options)
      .replace(',', '');
    return formattedDate;
  }

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  useEffect(() => {
    const { phoneNumber, workerName } = filters; // Extract phoneNumber and workerName filters
    if (callRecords.length > 0) {
      let filteredData = callRecords;

      if (startDateRange && endDateRange) {
        filteredData = filteredData.filter(data => {
          const createdAtDate = new Date(data.createdAt);
          return createdAtDate >= startDateRange && createdAtDate <= endDateRange;
        });
      }
      // Apply phoneNumber filter if provided
      if (phoneNumber) {
        filteredData = filteredData.filter(data =>
          data.phoneNumber?.toLowerCase().includes(phoneNumber.toLowerCase())

        );
      }

      // Apply workerName filter if provided
      if (workerName) {
        filteredData = filteredData.filter(data =>
          data.workerName?.toLowerCase().includes(workerName.toLowerCase())
        );
      }

      if (workerStatus && workerStatus !== 'all') {
        filteredData = filteredData.filter(data => data.workerStatus === workerStatus);
      }


      // Add index and update state
      setCallRecordsWithIndex(filteredData?.map((record, i) => ({ ...record, index: i + 1 })));
    }
  }, [filters, workerStatus, callRecords, startDateRange, endDateRange]);

  const handleDateRange = (event, picker) => {
    setStartDateRange(picker.startDate.toDate());
    setEndDateRange(picker.endDate.toDate());
  };

  const handleViewClick = (row) => {
    setViewData(row);
    setSlideUpVisibleForViewData(true)
  };

  const handleInputChange = (e, selectOption = null) => {
    if (selectOption) {
      setCallRecord((prevCallRecord) => ({
        ...prevCallRecord,
        [e.name]: selectOption
      }));
    } else {
      const { name, value } = e.target;
      setCallRecord((prevCallRecord) => ({
        ...prevCallRecord,
        [name]: value
      }));
    }
  };

  const closeModal = () => {
    setName('');
    setAudioUrl('');
    setAudioSlideUpVisible(false);
  };

  const tableColumns = [
    {
      dataField: 'index',
      text: '#',
      headerStyle: { backgroundColor: '#f0f0f073' },
    },
    {
      dataField: 'workerName',
      text: 'Worker Name',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
    },
    {
      dataField: 'workerDob',
      text: 'Technician Phone No',
      headerStyle: { backgroundColor: '#f0f0f073' },
      //   sort: true,
    },
    {
      dataField: 'workOrderNumber',
      text: 'Order No',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
    },
    {
      dataField: 'phoneNumber',
      text: 'Phone No',
      headerStyle: { backgroundColor: '#f0f0f073' },
      //   sort: true,
    },
    {
      dataField: 'workerStatus',
      text: 'Status',
      headerStyle: { backgroundColor: '#f0f0f073' },
      formatter: (cell) => {
        if (typeof cell === 'string') {
          // Define the status to className map
          const statusClassMap = {
            'Completed': 'badge bg-success text-white fw-lighter p-2',
            'Additional Work Found': 'badge bg-primary text-white fw-lighter p-2',
            'Requires Part': 'badge bg-secondary fw-lighter p-2',
            'Requires Follow-up': 'badge bg-secondary fw-lighter p-2',
            'Requires Authorization': 'badge bg-danger text-white fw-lighter p-2',
          };

          // Get the capitalized status
          const capitalizedStatus = cell
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

          // Determine the appropriate className based on the status
          const className = statusClassMap[capitalizedStatus] || 'badge bg-dark'; // Default class
          return <span className={className}>{capitalizedStatus}</span>;
        }
        return cell;
      },
      //   sort: true,
    },
    {
      dataField: 'checkIn',
      text: 'Date',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        const dateA = new Date(a).getTime();
        const dateB = new Date(b).getTime();
        if (order === 'asc') {
          return dateA - dateB;
        }
        return dateB - dateA;
      },
      formatter: (cell, row) => {
        if (cell) {
          return (
            <>
              <span>
                {new Date(cell).toLocaleString('en-US', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                })}
              </span>
            </>
          );
        } else {
          return '';
        }
      },
    },
    {
      dataField: 'checkIn',
      text: 'Check In',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        const dateA = new Date(a).getTime();
        const dateB = new Date(b).getTime();
        if (order === 'asc') {
          return dateA - dateB;
        }
        return dateB - dateA;
      },
      formatter: (cell, row) => {
        if (cell) {
          return (
            <>
              <span>
                {new Date(cell).toLocaleString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: true,
                })}
              </span>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  flexDirection: "row",
                  cursor: 'pointer',
                  marginTop: "7px"
                }}
                onClick={() => handleClickUrlOne(row)}
              >
                <div>
                  {row?.checkInRecordingUrl && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#007bff"
                      className="bi bi-play-circle"
                      viewBox="0 0 16 16"
                      style={{ marginRight: '5px' }}
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                    </svg>
                  )}
                </div>
                <div>{row?.checkInCallDuration ? `${row?.checkInCallDuration} sec` : ''}</div>
              </div>
            </>
          );
        } else {
          return '';
        }
      },
      // sort: true,
    },
    {
      dataField: 'checkOut',
      text: 'Check Out',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        const dateA = new Date(a).getTime();
        const dateB = new Date(b).getTime();
        if (order === 'asc') {
          return dateA - dateB;
        }
        return dateB - dateA;
      },
      formatter: (cell, row) => {
        if (cell) {
          return (
            <>
              <span>
                {new Date(cell).toLocaleString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: true,
                })}
              </span>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  flexDirection: "row",
                  cursor: 'pointer',
                  marginTop: "7px"
                }}
                onClick={() => handleClickUrlTwo(row)}
              >
                <div>
                  {row?.checkOutRecordingUrl && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#007bff"
                      className="bi bi-play-circle"
                      viewBox="0 0 16 16"
                      style={{ marginRight: '5px' }}
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                    </svg>
                  )}
                </div>
                <div>{row?.checkOutCallDuration ? `${row?.checkOutCallDuration} sec` : ''}</div>
              </div>
            </>
          );
        } else {
          return '';
        }
      },
    },
    {
      dataField: 'workHours',
      text: 'Work Hours',
      headerStyle: { backgroundColor: '#f0f0f073' },
      formatter: (cell) => {
        if (typeof cell !== 'string' || !cell.includes('.')) {
          return '';
        }
        const [hours, minutes] = cell.split('.');
        return `${hours}Hr ${minutes}Min`;
      },
    },
    {
      dataField: 'manualEntry',
      text: 'Manual Entry',
      headerStyle: { backgroundColor: '#f0f0f073' },
      csvExport: false,
      formatter: (cell) => {
        if (!cell) return '';
        if (!cell?.role) return '-';

        return (
          <>
            {`${cell.role.charAt(0).toUpperCase() + cell.role.slice(1).toLowerCase()}`}
            {cell.editedBy?.name ? ` - ${cell.editedBy.name}` : ''}
            <br />
            {cell.editedAt ? `(${formateDate(cell.editedAt)})` : ''}
          </>
        );
      },
    },
    {
      text: 'Actions',
      headerStyle: { backgroundColor: '#f0f0f073' },
      csvExport: false,
      formatter: (cell, row) => (
        <div>
          <Button
            style={{ backgroundColor: 'green', borderColor: 'green' }}
            type="primary"
            utilclass="m-r-5"
            iconposition="center"
            onClick={() => handleViewClick(row)}
          >
            <i className="pg-icon"><RiShareBoxFill color='white' /></i>
          </Button>
          <Button
            type="primary"
            utilclass="m-r-5"
            iconposition="center"
            onClick={() => handleEditClick(row)}
          >
            <i className="pg-icon">edit</i>
            {/* <span className="">Edit</span> */}
          </Button>
          <Button
            type="danger"
            iconposition="center"
            onClick={() => handleDeleteClick(row)}
          >
            <i className="pg-icon">trash</i>
            {/* Delete */}
          </Button>
        </div>
      ),
    },
    // {
    //   dataField: 'checkInCallDuration',
    //   text: 'Check In Call',
    //   formatter: (cell, row) => {
    //     return (
    //       <div
    //         style={{
    //           display: 'flex',
    //           alignItems: 'center',
    //           gap: '5px',
    //           justifyContent: 'center',
    //           cursor: 'pointer',
    //         }}
    //         onClick={() => handleClickUrlOne(row)}
    //       >
    //         <div>
    //           {cell && row?.checkInRecordingUrl && (
    //             <svg
    //               xmlns="http://www.w3.org/2000/svg"
    //               width="16"
    //               height="16"
    //               fill="#007bff"
    //               className="bi bi-play-circle"
    //               viewBox="0 0 16 16"
    //               style={{ marginRight: '5px' }}
    //             >
    //               <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
    //               <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
    //             </svg>
    //           )}
    //         </div>
    //         <div>{cell ? `${cell} sec` : ''}</div>
    //       </div>
    //     );
    //   },
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    // },
    // {
    //   dataField: 'checkOutCallDuration',
    //   text: 'Check Out Call',
    //   formatter: (cell, row) => {
    //     return (
    //       <div
    //         style={{
    //           display: 'flex',
    //           alignItems: 'center',
    //           gap: '5px',
    //           justifyContent: 'center',
    //           cursor: 'pointer',
    //         }}
    //         onClick={() => handleClickUrlTwo(row)}
    //       >
    //         <div>
    //           {cell && row?.checkOutRecordingUrl && (
    //             <svg
    //               xmlns="http://www.w3.org/2000/svg"
    //               width="16"
    //               height="16"
    //               fill="#007bff"
    //               className="bi bi-play-circle"
    //               viewBox="0 0 16 16"
    //               style={{ marginRight: '5px' }}
    //             >
    //               <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
    //               <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
    //             </svg>
    //           )}
    //         </div>
    //         <div>{cell ? `${cell} sec` : ''}</div>
    //       </div>
    //     );
    //   },
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    // },
  ];

  useEffect(() => {
    setExportCallRecordsData(nullToEmpty(callRecordsWithIndex))
  }, [callRecordsWithIndex])

  return (
    <div className="page-container">
      <div className="page-content-wrapper">
        <div className="content">
          <PageContainer className="container-fluid container-fixed-lg bg-white">
            <div className="card card-transparent">
              <div className="card-header d-flex flex-column justify-content-between">
                <div className="d-flex flex-row justify-content-between w-100 mb-4">
                  <h3 className="text-primary no-margin text-uppercase">Work Order - {workOrderNumber}</h3>
                  <div>
                    {csvProps && (
                      <ExportCSVButton {...csvProps}><i className="pg-icon m-r-10"><FiShare size={'15'} /></i> Export CSV</ExportCSVButton>
                    )}
                  </div>
                </div>
                <div className="export-options-container">
                  <div className="exportOptions">
                    <div className="stats row">
                      <div className="col-lg-2 col-md-4 col-sm-6">
                        <Card className="d-flex align-items-center justify-content-center text-center py-2 bg-primary text-white">
                          <CardBody className="p-0">
                            <h5 className='text-uppercase'>Work Hours</h5>
                            <h5>{calculateTotalWorkHours(callRecordsWithIndex)}</h5>
                          </CardBody>
                        </Card>
                      </div>

                      <div className="col-lg-2 col-md-4 col-sm-6">
                        <Card className="d-flex align-items-center justify-content-center text-center py-2 text-white" style={{ backgroundColor: 'gray', borderColor: 'gray' }}>
                          <CardBody className="p-0">
                            <h5 className='text-uppercase'>Workers</h5>
                            <h5>{calculateTotalWorkers(callRecordsWithIndex)}</h5>
                          </CardBody>
                        </Card>
                      </div>

                      <div className="col-lg-2 col-md-4 col-sm-6">
                        <Card className="d-flex align-items-center justify-content-center text-center py-2 text-white" style={{ backgroundColor: 'green', borderColor: 'green' }}>
                          <CardBody className="p-0">
                            <h5 className='text-uppercase'>Check Ins</h5>
                            <h5>{calculateTotalCheckIns(callRecordsWithIndex)}</h5>
                          </CardBody>
                        </Card>
                      </div>

                      <div className="col-lg-2 col-md-4 col-sm-6">
                        <Card className="d-flex align-items-center justify-content-center text-center py-2 bg-danger text-white">
                          <CardBody className="p-0">
                            <h5 className='text-uppercase'>Check Outs</h5>
                            <h5>{calculateTotalCheckOuts(callRecordsWithIndex)}</h5>
                          </CardBody>
                        </Card>
                      </div>

                      <div className="col-lg-4 col-md-8 col-sm-12">
                        <Card className="px-4 py-2 text-dark" style={{ border: '1px solid grey' }}>
                          <CardBody className="p-0 d-flex justify-content-between">
                            <div>
                              <p className='text-uppercase mb-2'>Store Name</p>
                              <p className='mb-1'>{worksiteManagerData.storeName}</p>
                            </div>

                            <div>
                              <p className='text-uppercase mb-2'>Store Address</p>
                              <p className='mb-1'>{worksiteManagerData.address}, {worksiteManagerData.address2}</p>
                              <p className='mb-1'>{worksiteManagerData.city}, {worksiteManagerData.state}</p>
                              <p className='mb-1'>{worksiteManagerData.zip}, {worksiteManagerData.country}</p>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </div>

                    <div className="DTTT btn-group d-flex align-items-center">
                      <div className="input-group  col-md-3">
                        <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                        <input type="text" className="form-control p-l-10" placeholder="Search by worker name" name='workerName' value={filters.workerName} onChange={handleSearchChange} />
                      </div>

                      <div className="input-group  col-md-3">
                        <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                        <input type="text" className="form-control p-l-10" placeholder="Search by phone number" name='phoneNumber' value={filters.phoneNumber} onChange={handleSearchChange} />
                      </div>

                      <div className="input-group  col-md-2">
                        <GroupSelect
                          placeholder='Status'
                          inputId='worksiteId'
                          value={workerStatus}
                          options={worksiteOptionsForFilter}
                          onChange={(selectedOption) => setWorkerStatus(selectedOption?.value)}
                        />
                      </div>

                      <DateRangePicker startDate={startDateRange} endDate={endDateRange} showTimeSelect onApply={handleDateRange}>
                        <div className="input-group col-md-5">
                          <span className="input-group-text input-date-icon-pre">
                            <i className="pg-icon">calendar</i>
                          </span>
                          <input type="text" name="reservation" style={{ width: "850px" }} id="daterangepicker" onChange={() => { }} className="form-control"
                            value={`${moment(startDateRange).format("MM/DD/YYYY h:mm A")} - ${moment(endDateRange).format("MM/DD/YYYY h:mm A")}`}
                          />
                        </div>
                      </DateRangePicker>


                      {audioSlideUpVisible ? (
                        <SlideUpModal visible={audioSlideUpVisible} width={'400'} effect="fadeInUp" onClickAway={() => closeModal()}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-top">
                                <button
                                  aria-label=""
                                  type="button"
                                  className="close"
                                  onClick={() => closeModal()}
                                  aria-hidden="true"
                                >
                                  <i className="pg-icon">close</i>
                                </button>
                                <h4 className="text-primary no-margin text-uppercase text-center">{name}</h4>
                              </div>
                              {audioUrl?.length > 0 ? (
                                <div className="modal-body">
                                  <form role="form">
                                    <div className="form-group-attached">
                                      <div className="row justify-content-center">
                                        <audio controls style={{ margin: "0", height: '40px' }}>
                                          <source src={audioUrl} type="audio/mpeg" />
                                        </audio>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              ) : (
                                <div className="modal-body">
                                  <div className="row justify-content-center">
                                    <div class="spinner-grow" role="status">
                                      <span class="visually-hidden">Loading...</span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </SlideUpModal>
                      ) : null}

                      {slideUpVisibleForDelete ?
                        <SlideUpModal visible={slideUpVisibleForDelete} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisibleForDelete(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-top">
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisibleForDelete(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                                <h5>Are you sure you want to delete?</h5>
                              </div>
                              <div className="modal-body">
                                <div className="row">
                                  <div className="col-md-6">
                                    <button aria-label="" type="button" className="btn btn-secondary btn-block" onClick={() => setSlideUpVisibleForDelete(false)}>
                                      Cancel
                                    </button>
                                  </div>
                                  <div className="col-md-6">
                                    <button aria-label="" type="button" className="btn btn-danger btn-block" onClick={handleDelete}>
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SlideUpModal> : null}

                      {slideUpVisibleForViewData ?
                        <SlideUpModal visible={slideUpVisibleForViewData} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisibleForViewData(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5>Call Record Details</h5>
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisibleForViewData(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                              </div>
                              <div className="modal-body py-3 h100vh">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Worker Name</p>
                                          {viewData.workerName ?
                                            <p class="bold mb-0">{viewData.workerName}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Phone Number</p>
                                          {viewData.phoneNumber ?
                                            <p class="bold mb-0">{viewData.phoneNumber}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Check In</p>
                                          {viewData.checkIn ?
                                            <p class="bold mb-0">{formateDate(viewData.checkIn)}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Check In Call Duration</p>
                                          {viewData.checkInCallDuration ?
                                            <p class="bold mb-0">{`${viewData?.checkInCallDuration} sec` || 'N/A'}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Created At</p>
                                          <p class="bold mb-0">{formateDate(viewData?.createdAt)}</p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Worker Status</p>
                                          {viewData.workerStatus ?
                                            <p class="bold mb-0">{viewData.workerStatus}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Work Order Number</p>
                                          {viewData.workOrderNumber ?
                                            <p class="bold mb-0">{viewData.workOrderNumber}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Technician Phone No</p>
                                          {viewData.workerDob ?
                                            <p class="bold mb-0">{viewData.workerDob}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Check Out</p>
                                          {viewData.checkOut ?
                                            <p class="bold mb-0">{formateDate(viewData.checkOut)}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Check Out Call Duration</p>
                                          {viewData.checkOutCallDuration ?
                                            <p class="bold mb-0">{`${viewData?.checkOutCallDuration} sec` || 'N/A'}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Updated At</p>
                                          <p class="bold mb-0">{formateDate(viewData?.updatedAt)}</p>
                                        </div>
                                      </div>
                                    </div>

                                    {viewData && viewData?.manualEntry?.role && <div className='social-card share w-100 mb-3'>
                                      <div className="pgn-message card-header">
                                        <div>
                                          <p>Manual Entry</p>
                                          <p className="bold mb-0"><span className='text-capitalize'>{viewData?.manualEntry?.role}</span> - {viewData?.manualEntry?.editedBy?.name} <br /> ({formateDate(viewData?.manualEntry?.editedAt)})</p>
                                        </div>
                                      </div>
                                    </div>}

                                    {/* <p><strong>Check In Recording URL:</strong> {viewData?.checkInRecordingUrl || 'N/A'}</p> */}
                                    {/* <p><strong>Check Out Recording URL:</strong> {viewData?.checkOutRecordingUrl || 'N/A'}</p> */}
                                    {/* <p><strong>Check In Call SID:</strong> {viewData?.checkInCallSID || 'N/A'}</p>
                                    <p><strong>Check Out Call SID:</strong> {viewData?.checkOutCallSID || 'N/A'}</p>
                                    <p><strong>Check In Recording SID:</strong> {viewData?.checkInRecordingSID || 'N/A'}</p>
                                    <p><strong>Check Out Recording SID:</strong> {viewData?.checkOutRecordingSID || 'N/A'}</p> */}
                                    {/* <p><strong>Worksite:</strong> {viewData?.worksiteId}</p> */}

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SlideUpModal>
                        : null
                      }

                      {slideUpVisible ?
                        <SlideUpModal visible={slideUpVisible} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisible(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5>Edit Call Record</h5>
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisible(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                              </div>
                              <div className="modal-body p-5">
                                <form role="form">
                                  <div class="input-group flex-column">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label for='phoneNumber'>Phone Number</label>
                                        <input type="text" className="form-control w-100" placeholder='Update Phone Number' value={callRecord.phoneNumber} onChange={handleInputChange} name='phoneNumber' id='phoneNumber' />
                                      </div>
                                      <div className="col-md-6">
                                        <label for='workerName'>Worker Name</label>
                                        <input type="text" className="form-control w-100" placeholder='Update Worker Name' value={callRecord.workerName} onChange={handleInputChange} name='workerName' id='workerName' />
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-4">
                                        <label for='workerDob' className='mt-3'>Technician Phone No</label>
                                        <input type="text" className="form-control w-100" placeholder='Update Technician Phone No' value={callRecord.workerDob} onChange={handleInputChange} name='workerDob' id='workerDob' />
                                      </div>

                                      <div className="col-md-4">
                                        <label htmlFor='workerStatus' className='mt-3'>Worker Status</label>
                                        <GroupSelect
                                          inputId='workerStatus'
                                          value={callRecord.workerStatus}
                                          options={workerStatusOptions}
                                          onChange={(selectedOption) => handleInputChange({ name: 'workerStatus' }, selectedOption?.value)}
                                        />
                                      </div>

                                      <div className="col-md-4">
                                        <label for='workOrderNumber' className='mt-3'>Work Order Number</label>
                                        <input type="text" className="form-control w-100" placeholder='Update Work Order Number' value={callRecord.workOrderNumber} onChange={handleInputChange} name='workOrderNumber' id='workOrderNumber' />
                                      </div>
                                    </div>

                                    <label for='checkIn' className='mt-3'>Check In<span style={{ color: 'red' }}>*</span></label>
                                    <DatePicker
                                      onChange={(selectedOption) => handleInputChange({ name: 'checkIn' }, selectedOption)}
                                      showTimeSelect
                                      timeFormat="HH:mm"
                                      timeIntervals={1}
                                      timeCaption="Time"
                                      dateFormat="MMMM d, yyyy h:mm aa"
                                      placeholderText="Select date and time"
                                      className="form-control w-100"
                                      selected={callRecord.checkIn}
                                      name="checkIn"
                                      id="checkIn"
                                    />

                                    <label for='checkOut' className='mt-3'>Check Out<span style={{ color: 'red' }}>*</span></label>
                                    <DatePicker
                                      onChange={(selectedOption) => handleInputChange({ name: 'checkOut' }, selectedOption)}
                                      showTimeSelect
                                      timeFormat="HH:mm"
                                      timeIntervals={1}
                                      timeCaption="Time"
                                      dateFormat="MMMM d, yyyy h:mm aa"
                                      placeholderText="Select date and time"
                                      className="form-control w-100"
                                      selected={callRecord.checkOut}
                                      name='checkOut'
                                      id='checkOut'
                                    />

                                  </div>

                                </form>
                              </div>
                              <div className='modal-footer'>
                                <button aria-label="" type="button" className="btn btn-primary" onClick={handleEdit}>
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </SlideUpModal>
                        : null
                      }
                    </div>
                  </div>
                </div>
              </div>

              {!isLoading ? <div className="card-body custable">
                {callRecordsWithIndex?.length > 0 ? (
                  <ToolkitProvider
                    keyField="id"
                    data={exportCallRecordsData}
                    columns={tableColumns}
                    exportCSV={{
                      fileName: 'callRecords-data.csv',
                      ignoreHeader: false,
                      noAutoBOM: false,
                    }}
                  >
                    {(props) => {
                      csvPropsRef.current = props.csvProps;
                      if (!csvProps) {
                        setCsvProps(props.csvProps);
                      }
                      return (
                        <React.Fragment>
                          <BootstrapTable
                            {...props.baseProps}
                            hover={true}
                            bootstrap4
                            bordered={false}
                            condensed={true}
                            striped={true}
                            pagination={paginationFactory()}
                          // cellEdit={cellEditFactory({
                          //   mode: 'click',
                          //   blurToSave: false,
                          // })}
                          />
                        </React.Fragment>
                      )
                    }}
                  </ToolkitProvider>
                ) : (
                  <NoData />
                )}
              </div> : <Loader />}
            </div>
          </PageContainer>
        </div>
      </div>
    </div>
  );
};

export default CallRecordsByWorkOrderNumber;
