import React, { useEffect, useState } from 'react';
import PageContainer from '../UIElements/Containers';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../UIElements/Button/UtilsButton';
import SlideUpModal from '../Modals/SlideUpModal';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { editAdminDetails, editPassword, getAdminDetails, resetEditAdminDetails, resetEditAdminDetailsError, resetEditPasswordData, resetEditPasswordErrorData } from '../../api-actions/adminDataActions';
import { handleLogout } from '../../utils/logout';

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jwtToken = localStorage.getItem('jwtToken');
  const adminDetailsEmptyState = { adminName: '', adminEmail: '', adminTollFreeNumber: '', adminPhoneNUmber: '' };
  const adminUpdatePasswordEmptyState = { adminPassword: '', adminNewPassword: '' };

  const userType = useSelector((state) => state.AdminAuthData.userType);
  const adminId = useSelector((state) => state.AdminAuthData.userId);
  const adminData = useSelector((state) => state.AdminData.getAdminData);
  const getAdminDetailsError = useSelector((state) => state.AdminData.getAdminError);
  const editAdminDetailsRes = useSelector((state) => state.AdminData.editAdminDetailsData);
  const editAdminDetailsError = useSelector((state) => state.AdminData.editAdminDetailsError);
  const editAdminPasswordData = useSelector((state) => state.AdminData.editAdminPasswordData);
  const editAdminPasswordError = useSelector((state) => state.AdminData.editAdminPasswordError);

  const [adminDetails, setAdminDetails] = useState(adminDetailsEmptyState);
  const [adminUpdatePassword, setAdminUpdatePassword] = useState(adminUpdatePasswordEmptyState);
  const [adminConfirmPassword, setAdminConfirmPassword] = useState('');

  useEffect(() => {
    if (adminId) {
      dispatch(getAdminDetails(jwtToken, adminId));
    }
  }, [adminId]);

  useEffect(() => {
    if (getAdminDetailsError && Object.keys(getAdminDetailsError)?.length > 0) {
      dispatch(resetGetAdminDetailsError());
    }
  }, [getAdminDetailsError]);

  useEffect(() => {
    if (editAdminDetailsRes && Object.keys(editAdminDetailsRes)?.length > 0) {
      setAdminDetails(adminDetailsEmptyState);
      toast.success('Admin details updated successfully');
      setSlideUpVisible(false);
      dispatch(getAdminDetails(jwtToken, adminId));
      dispatch(resetEditAdminDetails());
      localStorage.setItem('adminName', adminDetails.adminName);
    }
  }, [editAdminDetailsRes]);

  useEffect(() => {
    if (editAdminDetailsError && Object.keys(editAdminDetailsError)?.length > 0) {
      toast.error(editAdminDetailsError.message);
      dispatch(resetEditAdminDetailsError());
    }
  }, [editAdminDetailsError]);

  useEffect(() => {
    if (editAdminPasswordData && Object.keys(editAdminPasswordData)?.length > 0) {
      setAdminUpdatePassword(adminUpdatePasswordEmptyState);
      handleLogout(dispatch, navigate, 'Admin password updated successfully, you will logout soon. Please login again!');
      dispatch(resetEditPasswordData());
    }
  }, [editAdminPasswordData]);

  useEffect(() => {
    if (editAdminPasswordError && Object.keys(editAdminPasswordError)?.length > 0) {
      toast.error(editAdminPasswordError.message);
      dispatch(resetEditPasswordErrorData());
    }
  }, [editAdminPasswordError]);

  //slide-up modal
  const [slideUpVisible, setSlideUpVisible] = useState(false);
  const [slideUpVisibleForDelete, setSlideUpVisibleForDelete] = useState(false);
  const [slideUpModalSize, setSlideUpModalSize] = useState([false, true, false]);
  const [slideUpWidth, setSlideUpWidth] = useState("500");
  useEffect(() => {
    if (slideUpModalSize[0]) setSlideUpWidth("600");
    if (slideUpModalSize[1]) setSlideUpWidth("500");
    if (slideUpModalSize[2]) setSlideUpWidth("300");
  }, [slideUpModalSize]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAdminDetails((prevCorporateRecord) => ({
      ...prevCorporateRecord,
      [name]: value
    }));
  }

  const handleEditClick = () => {
    setSlideUpVisible(true)
    setAdminDetails({
      adminName: adminData.name,
      adminEmail: adminData.email,
    })
  }

  const handleEdit = () => {
    dispatch(editAdminDetails(jwtToken, adminId, adminDetails));
  }

  const handleInputChangeForUpdatePassword = (e) => {
    const { name, value } = e.target;
    setAdminUpdatePassword((prevCorporateData) => ({
      ...prevCorporateData,
      [name]: value
    }));
  }

  const handleEditForUpdatePassword = () => {
    if (adminUpdatePassword.adminNewPassword !== adminConfirmPassword) {
      toast.error('New password and confirm password do not match');
      return;
    }
    dispatch(editPassword(jwtToken, adminId, adminUpdatePassword));
  }

  return (
    <div className="page-container">
      <div className="page-content-wrapper">
        <div className="content">
          <PageContainer>
            <Card type={"transparent"}>
              <div className="row m-auto w-100">
                <div className="col-lg-6">
                  <Card className="p-4">
                    <CardBody className="p-0">
                      {userType === 'admin' ? (
                        <>
                          <h3 className="text-primary no-margin text-uppercase">Settings</h3>
                          <div className='d-flex align-items-center justify-content-between'>
                            <p className='text-capitalize mt-3'><b>Name:</b> {adminData.name}</p>
                            <Button type="primary" utilclass="m-r-5" iconposition="center" onClick={handleEditClick}>
                              <i className="pg-icon">edit</i>
                            </Button>
                          </div>
                          <p><b>Email:</b> {adminData.email}</p>
                        </>
                      ) : (
                        <p className='mb-0 text-center'>No Data</p>
                      )}
                    </CardBody>
                  </Card>

                  {slideUpVisible ?
                    <SlideUpModal visible={slideUpVisible} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisible(false)}>
                      <div className="modal-content-wrapper">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5>Edit admin record</h5>
                            <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisible(false)} data-dismiss="modal" aria-hidden="true">
                              <i className="pg-icon">close</i>
                            </button>
                          </div>
                          <div className="modal-body p-5">
                            <form role="form">
                              <div class="input-group flex-column">
                                <label for='adminName'>Name</label>
                                <input type="text" className="form-control w-100" placeholder='Update Name' value={adminDetails.adminName} onChange={handleInputChange} name='adminName' id='adminName' />
                              </div>
                            </form>
                          </div>

                          <div className='modal-footer'>
                            <button aria-label="" type="button" className="btn btn-primary py-2 px-3" onClick={handleEdit}>
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </SlideUpModal> : null}
                </div>

                <div className="col-lg-6">
                  <Card className="p-4">
                    <CardBody className="p-0">
                      {userType === 'admin' ? (
                        <>
                          <h3 className="text-primary no-margin text-uppercase">Update Password</h3>
                          <div className='d-flex flex-column'>
                            <input type="password" className="form-control mt-3" placeholder='Enter Old Password' value={adminUpdatePassword.adminPassword} name='adminPassword' onChange={handleInputChangeForUpdatePassword} />
                            <input type="password" className="form-control mt-3" placeholder='Enter New Password' value={adminUpdatePassword.adminNewPassword} name='adminNewPassword' onChange={handleInputChangeForUpdatePassword} />
                            <input type="password" className="form-control mt-3" placeholder='Confirm New Password' value={adminConfirmPassword} onChange={(e) => setAdminConfirmPassword(e.target.value)} />
                            <button aria-label="" type="button" className="btn btn-primary py-2 px-3 mt-3" onClick={handleEditForUpdatePassword}>
                              Update Password
                            </button>
                          </div>
                        </>
                      ) : (
                        <p className='mb-0 text-center'>No Data</p>
                      )}
                    </CardBody>
                  </Card>

                  {slideUpVisible ?
                    <SlideUpModal visible={slideUpVisible} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisible(false)}>
                      <div className="modal-content-wrapper">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5>Edit admin record</h5>
                            <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisible(false)} data-dismiss="modal" aria-hidden="true">
                              <i className="pg-icon">close</i>
                            </button>
                          </div>
                          <div className="modal-body p-5">
                            <form role="form">
                              <div class="input-group flex-column">
                                <label for='adminName'>Name</label>
                                <input type="text" className="form-control w-100" placeholder='Update Name' value={adminDetails.adminName} onChange={handleInputChange} name='adminName' id='adminName' />
                              </div>
                            </form>
                          </div>

                          <div className='modal-footer'>
                            <button aria-label="" type="button" className="btn btn-primary py-2 px-3" onClick={handleEdit}>
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </SlideUpModal> : null}
                </div>
              </div>
            </Card>
          </PageContainer>
        </div>
      </div>
    </div>
  );
};

export default Settings;
