import React, { useEffect, useState } from 'react';
import PageContainer from '../UIElements/Containers';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import { useDispatch, useSelector } from 'react-redux';
import { editPassword, editRecordByCorporate, getCorporateRecord, resetCorporateRecordsErrorData, resetCorporateUpdatePasswordErrorData, resetEditCorporateRecordData, resetEditCorporateUpdatePasswordData, resetGetCorporateRecordErrorData } from '../../api-actions/corporateDataAction';
import Button from '../UIElements/Button/UtilsButton';
import SlideUpModal from '../Modals/SlideUpModal';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { handleLogout } from '../../utils/logout';
import InputWithLabel from '../Forms/Elements/InputWithLabel';

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jwtToken = localStorage.getItem('jwtToken');
  const corporateRecordEmptyState = { corporateName: '', corporateEmail: '', corporateTollFreeNumber: '', corporatePhoneNumber: '', corporateStartRange: '', corporateEndRange: '' };
  const corporateUpdatePasswordEmptyState = { corporatePassword: '', corporateNewPassword: '' };

  const userType = useSelector((state) => state.AdminAuthData.userType);
  const corporateId = useSelector((state) => state.AdminAuthData.userId);
  const corporateRecordData = useSelector((state) => state.CorporateData.getCorporateRecordData);
  const getCorporateRecordErrorData = useSelector((state) => state.CorporateData.getCorporateRecordError);
  const editCorporateRecordData = useSelector((state) => state.CorporateData.editCorporateRecordData);
  const editCorporateRecordError = useSelector((state) => state.CorporateData.editCorporateRecordError);
  const editCorporateUpdatePasswordData = useSelector((state) => state.CorporateData.editCorporateUpdatePasswordData);
  const editCorporateUpdatePasswordError = useSelector((state) => state.CorporateData.editCorporateUpdatePasswordError);

  const [corporateRecord, setCorporateRecord] = useState(corporateRecordEmptyState)
  const [corporateUpdatePassword, setCorporateUpdatePassword] = useState(corporateUpdatePasswordEmptyState)
  const [corporateConfirmPassword, setCorporateConfirmPassword] = useState('');
  
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (corporateId) {
      dispatch(getCorporateRecord(jwtToken, corporateId));
    }
  }, [corporateId]);

  useEffect(() => {
    if (getCorporateRecordErrorData && Object.keys(getCorporateRecordErrorData)?.length > 0) {
      dispatch(resetGetCorporateRecordErrorData());
    }
  }, [getCorporateRecordErrorData]);

  useEffect(() => {
    if (editCorporateRecordData && Object.keys(editCorporateRecordData)?.length > 0) {
      setCorporateRecord(corporateRecordEmptyState);
      toast.success('Corporate record updated successfully');
      setSlideUpVisible(false);
      dispatch(getCorporateRecord(jwtToken, corporateId));
      dispatch(resetEditCorporateRecordData());
      localStorage.setItem('adminName', corporateRecord.corporateName);
    }
  }, [editCorporateRecordData]);

  useEffect(() => {
    if (editCorporateRecordError && Object.keys(editCorporateRecordError)?.length > 0) {
      setSlideUpVisible(false)
      toast.error(editCorporateRecordError.message);
      dispatch(resetCorporateRecordsErrorData());
    }
  }, [editCorporateRecordError]);

  useEffect(() => {
    if (editCorporateUpdatePasswordData && Object.keys(editCorporateUpdatePasswordData)?.length > 0) {
      setCorporateUpdatePassword(corporateUpdatePasswordEmptyState);
      handleLogout(dispatch, navigate, 'Corporate password updated successfully, you will logout soon. Please login again!');
      dispatch(resetEditCorporateUpdatePasswordData());
    }
  }, [editCorporateUpdatePasswordData]);

  useEffect(() => {
    if (editCorporateUpdatePasswordError && Object.keys(editCorporateUpdatePasswordError)?.length > 0) {
      toast.error(editCorporateUpdatePasswordError.message);
      dispatch(resetCorporateUpdatePasswordErrorData());
    }
  }, [editCorporateUpdatePasswordError]);

  //slide-up modal
  const [slideUpVisible, setSlideUpVisible] = useState(false);
  const [slideUpVisibleForDelete, setSlideUpVisibleForDelete] = useState(false);
  const [slideUpModalSize, setSlideUpModalSize] = useState([false, true, false]);
  const [slideUpWidth, setSlideUpWidth] = useState("500");
  useEffect(() => {
    if (slideUpModalSize[0]) setSlideUpWidth("600");
    if (slideUpModalSize[1]) setSlideUpWidth("500");
    if (slideUpModalSize[2]) setSlideUpWidth("300");
  }, [slideUpModalSize]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCorporateRecord((prevCorporateRecord) => ({
      ...prevCorporateRecord,
      [name]: value
    }));
  }

  const handleEditClick = () => {
    setSlideUpVisible(true)
    setCorporateRecord({
      corporateName: corporateRecordData.name,
      corporateEmail: corporateRecordData.email,
      corporatePhoneNumber: corporateRecordData.phoneNumber,
      corporateTollFreeNumber: corporateRecordData.tollFreeNumber,
      corporateStartRange: corporateRecordData?.workOrderStartRange,
      corporateEndRange: corporateRecordData?.workOrderEndRange,
    });
  }

  const handleEdit = () => {
    if (Number(corporateRecord?.corporateStartRange) >= Number(corporateRecord?.corporateEndRange)) {
      toast.error("End range must be greater than start range");
      setSlideUpVisible(false);
      return 
    }
    dispatch(editRecordByCorporate(jwtToken, corporateId, corporateRecord));
  }

  const handleInputChangeForUpdatePassword = (e) => {
    const { name, value } = e.target;
    setCorporateUpdatePassword((prevCorporateData) => ({
      ...prevCorporateData,
      [name]: value
    }));
  }

  const handleEditForUpdatePassword = () => {
    if (corporateUpdatePassword.corporateNewPassword !== corporateConfirmPassword) {
      toast.error('New password and confirm password do not match');
      return;
    }
    dispatch(editPassword(jwtToken, corporateId, corporateUpdatePassword));
  }

  function formatTollFreeNumber(phoneNumber) {
    // Remove any non-digit characters
    const cleaned = phoneNumber.replace(/\D/g, '');
    return `+${cleaned[0]} (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7)}`;
  }

  return (
    <div className="page-container">
      <div className="page-content-wrapper">
        <div className="content">
          <PageContainer>
            <Card type={"transparent"}>
              <div className="row m-auto w-100">
                <div className="col-lg-6">
                  <Card className="p-4">
                    <CardBody className="p-0">
                      {userType === 'corporate' ? (
                        <>
                          <h3 className="text-primary no-margin text-uppercase">Settings</h3>
                          <div className='d-flex align-items-center justify-content-between'>
                            <p className='text-capitalize mt-3'><b>Name:</b> {corporateRecordData.name}</p>
                            <Button type="primary" utilclass="m-r-5" iconposition="center" onClick={handleEditClick}>
                              <i className="pg-icon">edit</i>
                            </Button>
                          </div>
                          <p><b>Email:</b> {corporateRecordData.email}</p>
                          <p><b>Toll Free Number:</b> {corporateRecordData?.tollFreeNumber && formatTollFreeNumber(corporateRecordData?.tollFreeNumber)}</p>
                          <p><b>Phone Number:</b> {corporateRecordData.phoneNumber}</p>
                          <p><b>Starting Work Order Range:</b> {corporateRecordData.workOrderStartRange}</p>
                          <p><b>Ending Work Order Range:</b> {corporateRecordData.workOrderEndRange}</p>
                        </>
                      ) : (
                        <p className='mb-0 text-center'>No Data</p>
                      )}
                    </CardBody>
                  </Card>

                  {slideUpVisible ?
                    <SlideUpModal visible={slideUpVisible} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisible(false)}>
                      <div className="modal-content-wrapper">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5>Edit Corporate Record</h5>
                            <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisible(false)} data-dismiss="modal" aria-hidden="true">
                              <i className="pg-icon">close</i>
                            </button>
                          </div>
                          <div className="modal-body px-5 py-3">
                            <form role="form">
                              <div className="input-group flex-column">
                                <label for='corporateName'>Name</label>
                                <input type="text" className="form-control w-100" placeholder='Update Name' value={corporateRecord.corporateName} onChange={handleInputChange} name='corporateName' id='corporateName' />
                              </div>
                              <div className="input-group flex-column pt-3">
                                <label for='corporateEmail'>Email</label>
                                <input disabled type="text" className="form-control w-100" placeholder='Update Email' value={corporateRecord.corporateEmail} onChange={handleInputChange} name='corporateEmail' id='corporateEmail' />
                              </div>
                              <div className="input-group flex-column pt-3">
                                <label for='corporatePhoneNumber'>PhoneNumber</label>
                                <input type="text" className="form-control w-100" placeholder='Update PhoneNumber' value={corporateRecord.corporatePhoneNumber} onChange={handleInputChange} name='corporatePhoneNumber' id='corporatePhoneNumber' />
                              </div>
                              <div className="input-group flex-column pt-3">
                                <label for='corporateTollFreeNumber'>Tollfree Number</label>
                                <input type="text" className="form-control w-100" placeholder='Update Tollfree Number' value={corporateRecord.corporateTollFreeNumber} onChange={handleInputChange} name='corporateTollFreeNumber' id='corporateTollFreeNumber' />
                              </div>

                              <div className='row gap-2'>
                                <div className="input-group flex-column pt-3 col-md-6">
                                <label for='corporateStartRange'>Starting Work Order Range</label>
                                <input type="text" className="form-control w-100" placeholder='Update Starting Work Order Range' value={corporateRecord.corporateStartRange} onChange={handleInputChange} name='corporateStartRange' id='corporateStartRange' />
                              </div>

                              <div className="input-group flex-column pt-3 col-md-6">
                                <label for='corporateEndRange'>Ending Work Order Range</label>
                                <input type="text" className="form-control w-100" placeholder='Update Ending Work Order Range' value={corporateRecord.corporateEndRange} onChange={handleInputChange} name='corporateEndRange' id='corporateEndRange' />
                              </div>
                              </div>
                            </form>
                          </div>

                          <div className='modal-footer'>
                            <button aria-label="" type="button" className="btn btn-primary py-2 px-3" onClick={handleEdit}>
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </SlideUpModal> 
                    : null}
                </div>

                <div className="col-lg-6">
                  <Card className="p-4">
                    <CardBody className="p-0">
                      {userType === 'corporate' ? (
                        <>
                          <h3 className="text-primary no-margin text-uppercase">Update Password</h3>
                          <div className='d-flex flex-column mt-3'>
                            <div className="form-group form-group-default required py-2 d-flex align-items-center justify-content-between">
                              <div style={{ flexGrow: 1 }}>
                                <InputWithLabel
                                  className="form-control"
                                  placeholder="Enter Old Password"
                                  name="corporatePassword"
                                  value={corporateUpdatePassword.corporatePassword}
                                  onChange={handleInputChangeForUpdatePassword}
                                  type={showOldPassword ? "text" : "password"}
                                />
                              </div>
                              <div
                                onClick={() => setShowOldPassword(!showOldPassword)}
                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                              >
                                {showOldPassword ? (
                                  <i className="pg-icon" style={{ cursor: 'pointer' }}>eye</i>
                                ) : (
                                  <i className="pg-icon" style={{ cursor: 'pointer' }}>eye_off</i>
                                )}
                              </div>
                            </div>
                            
                            <div className="form-group form-group-default required py-2 d-flex align-items-center justify-content-between mt-1">
                              <div style={{ flexGrow: 1 }}>
                                <InputWithLabel
                                  className="form-control"
                                  placeholder="Enter New Password"
                                  name="corporateNewPassword"
                                  value={corporateUpdatePassword.corporateNewPassword}
                                  onChange={handleInputChangeForUpdatePassword}
                                  type={showNewPassword ? "text" : "password"}
                                />
                              </div>
                              <div
                                onClick={() => setShowNewPassword(!showNewPassword)}
                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                              >
                                {showNewPassword ? (
                                  <i className="pg-icon" style={{ cursor: 'pointer' }}>eye</i>
                                ) : (
                                  <i className="pg-icon" style={{ cursor: 'pointer' }}>eye_off</i>
                                )}
                              </div>
                            </div>

                            <div className="form-group form-group-default required py-2 d-flex align-items-center justify-content-between mt-1">
                              <div style={{ flexGrow: 1 }}>
                                <InputWithLabel
                                  className="form-control"
                                  placeholder="Confirm New Password"
                                  value={corporateConfirmPassword}
                                  onChange={(e) => setCorporateConfirmPassword(e.target.value)}
                                  type={showConfirmPassword ? "text" : "password"}
                                />
                              </div>
                              <div
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                              >
                                {showConfirmPassword ? (
                                  <i className="pg-icon" style={{ cursor: 'pointer' }}>eye</i>
                                ) : (
                                  <i className="pg-icon" style={{ cursor: 'pointer' }}>eye_off</i>
                                )}
                              </div>
                            </div>

                            <button aria-label="" type="button" className="btn btn-primary py-2 px-3 mt-2" onClick={handleEditForUpdatePassword}>
                              Update Password
                            </button>
                          </div>
                        </>
                      ) : (
                        <p className='mb-0 text-center'>No Data</p>
                      )}
                    </CardBody>
                  </Card>

                  {/* {slideUpVisible ?
                    <SlideUpModal visible={slideUpVisible} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisible(false)}>
                      <div className="modal-content-wrapper">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5>Edit Corporate Record</h5>
                            <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisible(false)} data-dismiss="modal" aria-hidden="true">
                              <i className="pg-icon">close</i>
                            </button>
                          </div>
                          <div className="modal-body px-5 py-3">
                            <form role="form">
                              <div class="input-group flex-column">
                                <label for='corporateName'>Name</label>
                                <input type="text" className="form-control w-100" placeholder='Update Name' value={corporateRecord.corporateName} onChange={handleInputChange} name='corporateName' id='corporateName' />
                              </div>
                              <div class="input-group flex-column pt-3">
                                <label for='corporateEmail'>Email</label>
                                <input disabled type="text" className="form-control w-100" placeholder='Update Email' value={corporateRecord.corporateEmail} onChange={handleInputChange} name='corporateEmail' id='corporateEmail' />
                              </div>
                              <div class="input-group flex-column pt-3">
                                <label for='corporatePhoneNumber'>PhoneNumber</label>
                                <input type="text" className="form-control w-100" placeholder='Update PhoneNumber' value={corporateRecord.corporatePhoneNumber} onChange={handleInputChange} name='corporatePhoneNumber' id='corporatePhoneNumber' />
                              </div>
                              <div class="input-group flex-column pt-3">
                                <label for='corporateTollFreeNumber'>Tollfree Number</label>
                                <input type="text" className="form-control w-100" placeholder='Update Tollfree Number' value={corporateRecord.corporateTollFreeNumber} onChange={handleInputChange} name='corporateTollFreeNumber' id='corporateTollFreeNumber' />
                              </div>
                            </form>
                          </div>

                          <div className='modal-footer'>
                            <button aria-label="" type="button" className="btn btn-primary py-2 px-3" onClick={handleEdit}>
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </SlideUpModal> : 
                    null} */}
                </div>
                <div className="col-lg-4"></div>
              </div>
            </Card>
          </PageContainer>
        </div>
      </div>
    </div>
  );
};

export default Settings;
