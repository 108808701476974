import React, { useState, useEffect, useRef } from 'react';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import PageContainer from '../../UIElements/Containers';
import { toast } from 'react-toastify';
import {
  resetWorkSiteCompletedVisitsErrorData,
  resetEditCallRecordData,
  resetEditCallRecordErrorData,
  resetDeleteCallRecordData,
  resetDeleteCallRecordErrorData,
  getCallRecordingUrl,
  getWorksiteCompletedVisits,
  getSingleWorksite,
} from '../../../api-actions/workSiteDataAction';
import { useDispatch, useSelector } from 'react-redux';
import { FiShare } from 'react-icons/fi';
import Loader from '../../Loader/Loader';
import NoData from '../../NoData/NoData';
import GroupSelect from '../../Forms/Elements/GroupSelect';
import { nullToEmpty } from '../../../utils/csvExport';
import { Link } from 'react-router-dom';

const SummaryVisits = () => {
  const { ExportCSVButton } = CSVExport;
  const jwtToken = localStorage.getItem('jwtToken');
  const callRecordEmptyState = {
    phoneNumber: '',
    workerName: '',
    workerDob: '',
    workerStatus: '',
    workOrderNumber: '',
    checkIn: '',
    checkOut: '',
  };
  const newCallRecordEmptyState = {
    phoneNumber: '',
    workerName: '',
    workerDob: '',
    workerStatus: '',
    workOrderNumber: '',
    checkIn: '',
    checkOut: '',
  };
  const workerStatusFilterOptions = [{ value: 'all', label: 'All Status' }, { value: 'completed', label: 'Completed' }, { value: 'additional work found', label: 'Additional Work Found' }, { value: 'requires part', label: 'Requires Part' }, { value: 'requires follow-up', label: 'Requires Follow-up' }, { value: 'requires authorization', label: 'Requires Authorization' }]
  const workerStatusOptions = [{ value: 'completed', label: 'Completed' }, { value: 'additional work found', label: 'Additional Work Found' }, { value: 'requires part', label: 'Requires Part' }, { value: 'requires follow-up', label: 'Requires Follow-up' }, { value: 'requires authorization', label: 'Requires Authorization' }]

  let endDate = new Date();
  endDate.setDate(endDate.getDate() + 30);

  const dispatch = useDispatch();
  const worksiteManagerData = useSelector((state) => state.WorkSiteData.worksiteManagerData);
  const worksiteManagerError = useSelector((state) => state.WorkSiteData.worksiteManagerError);
  const callRecords = useSelector((state) => state.WorkSiteData.getWorksiteCompletedVisitsData);
  const callRecordsErrorData = useSelector((state) => state.WorkSiteData.getWorksiteCompletedVisitsError);

  const editCallRecordData = useSelector((state) => state.WorkSiteData.editCallRecordData);
  const editCallRecordError = useSelector((state) => state.WorkSiteData.editCallRecordError);
  const deleteCallRecordData = useSelector((state) => state.WorkSiteData.deleteCallRecordData);
  const deleteCallRecordError = useSelector((state) => state.WorkSiteData.deleteCallRecordError);

  const worksiteId = useSelector((state) => state.AdminAuthData.userId);

  const [callRecordsWithIndex, setCallRecordsWithIndex] = useState([]);
  const [aggregateValue, setAggregatevalue] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [workerStatus, setWorkerStatus] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [exportSummaryVisitsData, setExportSummaryVisitsData] = useState()

  const [audioUrl, setAudioUrl] = useState('');
  const [recordingUrl, setRecordingUrl] = useState('');
  const [audioSlideUpVisible, setAudioSlideUpVisible] = useState(false);
  const [name, setName] = useState("");
  const [csvProps, setCsvProps] = useState(null);
  const csvPropsRef = useRef(null);

  const [filters, setFilters] = useState({
    searchValue: '',
    workerStatus: '',
    workOrderNumber: ''
  });

  // GET Actions
  useEffect(() => {
    setIsLoading(true)
    if (worksiteId) {
      dispatch(getWorksiteCompletedVisits(jwtToken, worksiteId));
      dispatch(getSingleWorksite(jwtToken, worksiteId));
    }
  }, [worksiteId]);

  useEffect(() => {
    if (recordingUrl?.length > 0 && !audioSlideUpVisible) {
      const fetchRecording = async () => {
        try {
          const jwtToken = localStorage.getItem('jwtToken');
          setAudioSlideUpVisible(true);
          const url = await dispatch(getCallRecordingUrl(jwtToken, recordingUrl));
          if (url?.data) {
            const audioBlob = new Blob([url?.data], { type: 'audio/mpeg' });
            const audioObjectUrl = URL.createObjectURL(audioBlob);
            setAudioUrl(audioObjectUrl);
            setRecordingUrl('');
          } else {
            toast.error('Try again later');
            setAudioSlideUpVisible(false);
            setRecordingUrl("");
            setAudioUrl("");
            setName("");
          }
        } catch (error) {
          // toast.error('Try again later');
        }
      };
      fetchRecording();
    }
  }, [recordingUrl, audioSlideUpVisible, dispatch]);


  useEffect(() => {
    // const data = callRecords.map((record, i) => ({ ...record, index: i + 1 }));
    // setCallRecordsWithIndex(data);
    
    // const aggregatedData = Object.values(
    //   data.reduce((acc, curr) => {
    //     if (!acc[curr.workOrderNumber]) {
    //       acc[curr.workOrderNumber] = { ...curr, workHours: curr.workHours };
    //     } else {
    //       acc[curr.workOrderNumber].workHours = (
    //         parseFloat(acc[curr.workOrderNumber].workHours) + parseFloat(curr.workHours)
    //       ).toFixed(2);  // Convert back to string with 2 decimal places
    //     }
    //     return acc;
    //   }, {})
    // );
    
    // setAggregatevalue(aggregatedData)
    // setTimeout(() => {
    //   setIsLoading(false)
    // }, 1000)

  let filteredData = callRecords;

  if (filters.workOrderNumber) {
    filteredData = filteredData.filter(record =>
      record.workOrderNumber?.toLowerCase().includes(filters.workOrderNumber.toLowerCase())
    );
  }

  // if (workerStatus) {
  //   filteredData = filteredData.filter(record => 
  //     record.workerStatus === workerStatus
  //   );
  // }
  
  const dataWithIndex = filteredData.map((record, i) => ({
    ...record,
    index: i + 1,
  }));
  setCallRecordsWithIndex(dataWithIndex);

  const aggregatedData = Object.values(
    dataWithIndex.reduce((acc, curr) => {
      if (!acc[curr.workOrderNumber]) {
        acc[curr.workOrderNumber] = { ...curr, workHours: curr.workHours };
      } else {
        acc[curr.workOrderNumber].workHours = (
          parseFloat(acc[curr.workOrderNumber].workHours) + parseFloat(curr.workHours)
        ).toFixed(2); 
      }
      return acc;
    }, {})
  );

  setAggregatevalue(nullToEmpty(aggregatedData));

  setTimeout(() => {
    setIsLoading(false);
  }, 1000);
  }, [callRecords, filters, workerStatus]);

  useEffect(() => {
    if (callRecordsErrorData && Object.keys(callRecordsErrorData)?.length > 0) {
      dispatch(resetWorkSiteCompletedVisitsErrorData());
      setIsLoading(false)
    }
  }, [callRecordsErrorData]);

  // PATCH Actions
  useEffect(() => {
    if (editCallRecordData && Object.keys(editCallRecordData)?.length > 0) {
      setCallRecord(callRecordEmptyState);
      toast.success('Call record data updated successfully');
      setSlideUpVisible(false);
      dispatch(getWorksiteCompletedVisits(jwtToken, worksiteId));
      dispatch(resetEditCallRecordData());
    }
  }, [editCallRecordData]);

  useEffect(() => {
    if (editCallRecordError && Object.keys(editCallRecordError)?.length > 0) {
      toast.error(editCallRecordError.message);
      dispatch(resetEditCallRecordErrorData());
    }
  }, [editCallRecordError]);

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  useEffect(() => {
    if (deleteCallRecordData && Object.keys(deleteCallRecordData)?.length > 0) {
      toast.success('Call record data deleted successfully');
      dispatch(getWorksiteCompletedVisits(jwtToken, worksiteId));
      dispatch(resetDeleteCallRecordData());
    }
  }, [deleteCallRecordData]);

  useEffect(() => {
    if (deleteCallRecordError && Object.keys(deleteCallRecordError)?.length > 0) {
      toast.error(deleteCallRecordError.message);
      dispatch(resetDeleteCallRecordErrorData());
    }
  }, [deleteCallRecordError]);

  // Callback to get CSV props from ToolkitProvider
  // useEffect(() => {
  //   if (csvPropsRef.current) {
  //     setCsvProps(csvPropsRef.current);
  //   }
  // }, [csvPropsRef.current]);

  //slide-up modal
  const [slideUpVisible, setSlideUpVisible] = useState(false);
  const [slideUpVisibleForDelete, setSlideUpVisibleForDelete] = useState(false);
  const [slideUpModalSize, setSlideUpModalSize] = useState([false, true, false,]);
  const [slideUpWidth, setSlideUpWidth] = useState("500");
  useEffect(() => {
    if (slideUpModalSize[0]) setSlideUpWidth("600");
    if (slideUpModalSize[1]) setSlideUpWidth("700");
    if (slideUpModalSize[2]) setSlideUpWidth("300");
  }, [slideUpModalSize]);

  const tableColumns = [
    {
      dataField: 'index',
      text: '#',
      headerStyle: { backgroundColor: '#f0f0f073' },
    },
    {
      dataField: 'workOrderNumber',
      text: 'Work Order No',
      headerStyle: { backgroundColor: '#f0f0f073' },
      // sort: true,
      formatter: (cell, row) => {
        return (
          <Link to={`/worksite/work-order/${row.workOrderNumber}`}>
            {row.workOrderNumber}
          </Link>
        );
      },
    },
    {
      dataField: 'workHours',
      text: 'Total Working Hours',
      headerStyle: { backgroundColor: '#f0f0f073' },
      formatter: (cell) => {
        if (typeof cell !== 'string' || !cell.includes('.')) {
          return '';
        }
        const [hours, minutes] = cell.split('.');
        return `${hours}Hr ${minutes}Min`;
      },
    },
    {
      dataField: 'workerStatus',
      text: 'Status',
      headerStyle: { backgroundColor: '#f0f0f073' },
      formatter: (cell) => {
        if (typeof cell === 'string') {
          // Define the status to className map
          const statusClassMap = {
            'Completed': 'badge bg-success text-white fw-lighter p-2',
            'Additional Work Found': 'badge bg-primary text-white fw-lighter p-2',
            'Requires Part': 'badge bg-secondary fw-lighter p-2',
            'Requires Follow-up': 'badge bg-secondary fw-lighter p-2',
            'Requires Authorization': 'badge bg-danger text-white fw-lighter p-2',
          };

          // Get the capitalized status
          const capitalizedStatus = cell
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

          // Determine the appropriate className based on the status
          const className = statusClassMap[capitalizedStatus] || 'badge bg-dark'; // Default class
          return <span className={className}>{capitalizedStatus}</span>;
        }
        return cell;
      },
      // sort: true,
    },
  ];

  return (
    <div className="page-container">
      <div className="page-content-wrapper">
        <div className="content">
          <PageContainer className="container-fluid container-fixed-lg bg-white">
            <div className="card card-transparent">
              <div className="card-header d-flex flex-column justify-content-between">
                <div className="d-flex flex-row justify-content-between w-100 mb-4">
                  <h3 className="text-primary no-margin text-uppercase">Work Order Summary</h3>
                  <div>
                    {csvProps && (
                      <ExportCSVButton   {...csvProps}><i className="pg-icon m-r-10"><FiShare size={'15'} /></i> Export CSV</ExportCSVButton>
                    )}
                  </div>
                </div>

                {/* <div className="DTTT btn-group d-flex align-items-center" style={{ width: '23%' }}>
                  <div className="input-group m-r-15">
                    <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                    <input type="text" className="form-control p-l-10" placeholder="Search by work order number" name='workOrderNumber' value={filters.workOrderNumber} onChange={handleSearchChange} />
                  </div>
                </div> */}

                <div className='d-flex flex-row justify-content-start'>
                  <div className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0 px-1 p-l-0">
                    <div className="input-group m-r-15">
                      <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                      <input type="text" className="form-control p-l-10" placeholder="Search by work order number" name='workOrderNumber' value={filters.workOrderNumber} onChange={handleSearchChange} />
                    </div>  
                  </div>
                  {/* <div className="col-12 col-md-6 col-lg-2 mb-3 mb-lg-0 px-1">
                    <div className="input-group m-r-15">
                      <GroupSelect
                        placeholder='Status'
                        inputId='worksiteId'
                        value={workerStatus}
                        options={workerStatusFilterOptions}
                        onChange={(selectedOption) => setWorkerStatus(selectedOption?.value)}
                      />
                    </div>
                  </div> */}
                </div>
              </div>

              {!isLoading ? <div className="card-body custable">
                {aggregateValue?.length > 0 ? (
                  <ToolkitProvider
                    keyField="id"
                    data={aggregateValue}
                    columns={tableColumns}
                    exportCSV={{
                      fileName: 'summaryVisits-data.csv',
                      ignoreHeader: false,
                      noAutoBOM: false,
                    }}
                  >
                    {(props) => {
                      csvPropsRef.current = props.csvProps;
                      if (!csvProps) {
                        setCsvProps(props.csvProps);
                      }
                      return (
                        <React.Fragment>
                          <BootstrapTable
                            {...props.baseProps}
                            hover={true}
                            bootstrap4
                            bordered={false}
                            condensed={true}
                            striped={true}
                            pagination={paginationFactory()}
                          />
                        </React.Fragment>
                      )
                    }}
                  </ToolkitProvider>
                ) : (
                  <NoData />
                )}
              </div> : <Loader />}
            </div>
          </PageContainer>
        </div>
      </div>
    </div>
  );
};

export default SummaryVisits;
