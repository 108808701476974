import AdminDataActions from '../redux/actions/AdminDataAction';
import apiAdminWithJwt from '../services/apiAdminWithJwt';

// POST Methods
export const postCallRecord = (token, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .post('admin/create-one-call-record', data)
      .then((res) => {
        dispatch({
          type: AdminDataActions.POST_CALL_RECORD_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminDataActions.POST_CALL_RECORD_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const postPhoneNumber = (token, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .post('admin/create-phone-number', data)
      .then((res) => {
        dispatch({
          type: AdminDataActions.POST_PHONE_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminDataActions.POST_PHONE_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const postWorkOrderNumber = (token, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .post('admin/create-work-order-number', data)
      .then((res) => {
        dispatch({
          type: AdminDataActions.POST_WORK_ORDER_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminDataActions.POST_WORK_ORDER_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

// EDIT Methods
export const editAdminDetails = (token, id, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .patch(`admin/edit-admin-details/${id}`, data)
      .then((res) => {
        dispatch({
          type: AdminDataActions.EDIT_ADMIN_DETAILS_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminDataActions.EDIT_ADMIN_DETAILS_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const editCallRecord = (token, id, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .patch(`admin/edit-call-record/${id}`, data)
      .then((res) => {
        dispatch({
          type: AdminDataActions.EDIT_CALL_RECORD_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminDataActions.EDIT_CALL_RECORD_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const editPhoneNumber = (token, id, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .patch(`admin/edit-phone-number/${id}`, data)
      .then((res) => {
        dispatch({
          type: AdminDataActions.EDIT_PHONE_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminDataActions.EDIT_PHONE_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const editWorkOrderNumber = (token, id, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .patch(`admin/edit-work-order-number/${id}`, data)
      .then((res) => {
        dispatch({
          type: AdminDataActions.EDIT_WORK_ORDER_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminDataActions.EDIT_WORK_ORDER_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const editWorkSite = (token, id, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .patch(`admin/edit-worksite/${id}`, data)
      .then((res) => {
        dispatch({
          type: AdminDataActions.EDIT_WORK_SITE_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminDataActions.EDIT_WORK_SITE_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const editPassword = (token, id, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .patch(`admin-auth/password-reset/${id}`, data)
      .then((res) => {
        dispatch({
          type: AdminDataActions.EDIT_ADMIN_PASSWORD_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminDataActions.EDIT_ADMIN_PASSWORD_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

// DELETE Methods
export const deleteCallRecord = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .delete(`admin/delete-call-record/${id}`)
      .then((res) => {
        dispatch({
          type: AdminDataActions.DELETE_CALL_RECORD_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminDataActions.DELETE_CALL_RECORD_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const deletePhoneNumber = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .delete(`admin/delete-phone-number/${id}`)
      .then((res) => {
        dispatch({
          type: AdminDataActions.DELETE_PHONE_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminDataActions.DELETE_PHONE_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const deleteWorkOrderNumber = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .delete(`admin/delete-work-order-number/${id}`)
      .then((res) => {
        dispatch({
          type: AdminDataActions.DELETE_WORK_ORDER_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminDataActions.DELETE_WORK_ORDER_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const deleteWorkSite = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .delete(`admin/delete-worksite/${id}`)
      .then((res) => {
        dispatch({
          type: AdminDataActions.DELETE_WORK_SITE_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminDataActions.DELETE_WORK_SITE_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

// GET Methods
export const getCallRecords = (token) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get('admin/get-call-record',)
      .then((res) => {
        dispatch({
          type: AdminDataActions.GET_CALL_RECORDS_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminDataActions.GET_CALL_RECORDS_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getCompletedVisitsRecords = (token) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get('admin/reports/get-completed-visits',)
      .then((res) => {
        dispatch({
          type: AdminDataActions.GET_COMPLETED_VISITS_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminDataActions.GET_COMPLETED_VISITS_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getCheckInVisitsRecords = (token) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get('admin/reports/get-check-in-visits',)
      .then((res) => {
        dispatch({
          type: AdminDataActions.GET_CHECK_IN_VISITS_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminDataActions.GET_CHECK_IN_VISITS_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getWorkOrderSummary = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`/admin/reports/get-work-order-summary`)
      .then((res) => {
        
        dispatch({
          type: AdminDataActions.GET_WORK_ORDER_SUMMARY_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminDataActions.GET_WORK_ORDER_SUMMARY_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getAllPhoneNumber = (token) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get('admin/get-phone-number')
      .then((res) => {
        dispatch({
          type: AdminDataActions.GET_ALL_PHONE_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminDataActions.GET_ALL_PHONE_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getAllWorkOrderNumber = (token) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get('/admin/get-work-order')
      .then((res) => {
        dispatch({
          type: AdminDataActions.GET_ALL_WORK_ORDER_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminDataActions.GET_ALL_WORK_ORDER_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getAllLogRecords = (token) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get('/admin/get-log-records')
      .then((res) => {
        dispatch({
          type: AdminDataActions.GET_ALL_LOG_RECORDS_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminDataActions.GET_ALL_LOG_RECORDS_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getCallRecordingUrl = (token, recordingUrl) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    return await apiWithToken
      .get(
        `admin/get-recording-url?recordingUrl=${encodeURIComponent(
          recordingUrl
        )}`,
        { responseType: 'blob' }
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  };
};

export const getAdminDetails = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`admin/get-single-admin-user/${id}`,)
      .then((res) => {
        dispatch({
          type: AdminDataActions.GET_ADMIN_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminDataActions.GET_ADMIN_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const updateCheckInCallRecord = (token, id, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .patch(`admin/reports/update-check-in-call-record/${id}`, data)
      .then((res) => {
        dispatch({
          type: AdminDataActions.UPDATE_CHECK_IN_CALL_RECORD_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminDataActions.UPDATE_CHECK_IN_CALL_RECORD_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};


// POST Resets
export const resetPostCallRecordData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.POST_CALL_RECORD_DATA, payload: {} });
  };
};

export const resetPostCallRecordErrorData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.POST_CALL_RECORD_ERROR, payload: {} });
  };
};

export const resetPostPhoneNumberData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.POST_PHONE_NUMBER_DATA, payload: {} });
  };
};

export const resetPostPhoneNumberErrorData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.POST_PHONE_NUMBER_ERROR, payload: {} });
  };
};

export const resetPostWorkOrderNumberData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.POST_WORK_ORDER_NUMBER_DATA, payload: {} });
  };
};

export const resetPostWorkOrderNumberErrorData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.POST_WORK_ORDER_NUMBER_ERROR, payload: {} });
  };
};

// EDIT Resets
export const resetEditAdminDetails = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.EDIT_ADMIN_DETAILS_DATA, payload: {} });
  };
};

export const resetEditAdminDetailsError = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.EDIT_ADMIN_DETAILS_ERROR, payload: {} });
  };
};

export const resetEditCallRecordData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.EDIT_CALL_RECORD_DATA, payload: {} });
  };
};

export const resetEditCallRecordErrorData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.EDIT_CALL_RECORD_ERROR, payload: {} });
  };
};

export const resetEditPhoneNumberData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.EDIT_PHONE_NUMBER_DATA, payload: {} });
  };
};

export const resetEditPhoneNumberErrorData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.EDIT_PHONE_NUMBER_ERROR, payload: {} });
  };
};

export const resetEditWorkOrderNumberData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.EDIT_WORK_ORDER_NUMBER_DATA, payload: {} });
  };
};

export const resetEditWorkOrderNumberErrorData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.EDIT_WORK_ORDER_NUMBER_ERROR, payload: {} });
  };
};

export const resetEditWorkSiteData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.EDIT_WORK_SITE_DATA, payload: {} });
  };
};

export const resetEditWorkSitesErrorData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.EDIT_WORK_SITE_ERROR, payload: {} });
  };
};

export const resetEditPasswordData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.EDIT_ADMIN_PASSWORD_DATA, payload: {} });
  };
};

export const resetEditPasswordErrorData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.EDIT_ADMIN_PASSWORD_ERROR, payload: {} });
  };
};

export const resetUpdateCheckInCallRecordData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.UPDATE_CHECK_IN_CALL_RECORD_DATA, payload: {} });
  };
};

export const resetUpdateCheckInCallRecordError = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.UPDATE_CHECK_IN_CALL_RECORD_ERROR, payload: {} });
  };
};

// DELETE Resets
export const resetDeleteCallRecordData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.DELETE_CALL_RECORD_DATA, payload: {} });
  };
};

export const resetDeleteCallRecordErrorData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.DELETE_CALL_RECORD_ERROR, payload: {} });
  };
};

export const resetDeletePhoneNumberData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.DELETE_PHONE_NUMBER_DATA, payload: {} });
  };
};

export const resetDeletePhoneNumberErrorData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.DELETE_PHONE_NUMBER_ERROR, payload: {} });
  };
};

export const resetDeleteWorkOrderNumberData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.DELETE_WORK_ORDER_NUMBER_DATA, payload: {} });
  };
};

export const resetDeleteWorkOrderNumberErrorData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.DELETE_WORK_ORDER_NUMBER_ERROR, payload: {} });
  };
};

export const resetDeleteWorkSiteData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.DELETE_WORK_SITE_DATA, payload: {} });
  };
};

export const resetDeleteWorkSiteErrorData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.DELETE_WORK_SITE_ERROR, payload: {} });
  };
};

// GET Resets
export const resetCallRecordsErrorData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.GET_CALL_RECORDS_ERROR, payload: {} });
  };
};

export const resetCompletedVisitsError = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.GET_COMPLETED_VISITS_ERROR, payload: {} });
  };
};

export const resetCheckInVisitsError = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.GET_CHECK_IN_VISITS_ERROR, payload: {} });
  };
};

export const resetPhoneNumberErrorData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.GET_ALL_PHONE_NUMBER_ERROR, payload: {} });
  };
};

export const resetWorkOrderErrorData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.GET_ALL_WORK_ORDER_NUMBER_ERROR, payload: {} });
  };
};

export const resetLogRecordsErrorData = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.GET_ALL_LOG_RECORDS_ERROR, payload: {} });
  };
};

export const resetGetWorkOrderSummaryError = () => {
  return (dispatch) => {
    dispatch({ type: AdminDataActions.GET_WORK_ORDER_SUMMARY_ERROR, payload: {} });
  };
};


