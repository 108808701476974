import React, { useState, useEffect, useRef } from 'react';
import ToolkitProvider, {
  CSVExport,
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';

import Button from '../../UIElements/Button/UtilsButton';
import PageContainer from '../../UIElements/Containers';
import SlideUpModal from '../../Modals/SlideUpModal';
import { toast } from 'react-toastify';
import GroupSelect from "../../Forms/Elements/GroupSelect";
import {
  editCallRecordByWorksite,
  deleteCallRecordByWorksite,
  resetWorkSiteCompletedVisitsErrorData,
  resetEditCallRecordData,
  resetEditCallRecordErrorData,
  resetDeleteCallRecordData,
  resetDeleteCallRecordErrorData,
  getCallRecordingUrl,
  getWorksiteCompletedVisits,
  getSingleWorksite,
} from '../../../api-actions/workSiteDataAction';
import { useDispatch, useSelector } from 'react-redux';

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { RiShareBoxFill } from 'react-icons/ri';
import { FiShare } from 'react-icons/fi';
import Card from '../../Card/Card';
import CardBody from '../../Card/CardBody';
import { calculateTotalCheckIns, calculateTotalCheckOuts, calculateTotalWorkers, calculateTotalWorkHours } from '../../../utils/calculateTotal';
import Loader from '../../Loader/Loader';
import NoData from '../../NoData/NoData';
import { nullToEmpty } from '../../../utils/csvExport';
import { Link } from 'react-router-dom';

const CompletedVisits = () => {
  const { ExportCSVButton } = CSVExport;
  const jwtToken = localStorage.getItem('jwtToken');
  const callRecordEmptyState = {
    phoneNumber: '',
    workerName: '',
    workerDob: '',
    workerStatus: '',
    workOrderNumber: '',
    checkIn: '',
    checkOut: '',
  };
  const newCallRecordEmptyState = {
    phoneNumber: '',
    workerName: '',
    workerDob: '',
    workerStatus: '',
    workOrderNumber: '',
    checkIn: '',
    checkOut: '',
  };
  const workerStatusFilterOptions = [{ value: 'all', label: 'All Status' }, { value: 'completed', label: 'Completed' }, { value: 'additional work found', label: 'Additional Work Found' }, { value: 'requires part', label: 'Requires Part' }, { value: 'requires follow-up', label: 'Requires Follow-up' }, { value: 'requires authorization', label: 'Requires Authorization' }]
  const workerStatusOptions = [{ value: 'completed', label: 'Completed' }, { value: 'additional work found', label: 'Additional Work Found' }, { value: 'requires part', label: 'Requires Part' }, { value: 'requires follow-up', label: 'Requires Follow-up' }, { value: 'requires authorization', label: 'Requires Authorization' }]

  let endDate = new Date();
  endDate.setDate(endDate.getDate() + 30);

  const dispatch = useDispatch();
  const worksiteManagerData = useSelector((state) => state.WorkSiteData.worksiteManagerData);
  const worksiteManagerError = useSelector((state) => state.WorkSiteData.worksiteManagerError);
  const callRecords = useSelector((state) => state.WorkSiteData.getWorksiteCompletedVisitsData);
  const callRecordsErrorData = useSelector((state) => state.WorkSiteData.getWorksiteCompletedVisitsError);

  const editCallRecordData = useSelector((state) => state.WorkSiteData.editCallRecordData);
  const editCallRecordError = useSelector((state) => state.WorkSiteData.editCallRecordError);
  const deleteCallRecordData = useSelector((state) => state.WorkSiteData.deleteCallRecordData);
  const deleteCallRecordError = useSelector((state) => state.WorkSiteData.deleteCallRecordError);

  const [filters, setFilters] = useState({
    searchValue: '',
    phoneNumber: '',
    workerName: '',
    workerStatus: '',
    technicianPhoneNo: '',
    workOrderNumber: ''
  });

  const worksiteId = useSelector((state) => state.AdminAuthData.userId);

  const [callRecordsWithIndex, setCallRecordsWithIndex] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [startDateRange, setStartDateRange] = useState(new Date());
  const [endDateRange, setEndDateRange] = useState(endDate);
  const [currentCallRecordId, setCurrentCallRecordId] = useState(null);
  const [callRecord, setCallRecord] = useState(callRecordEmptyState)
  const [newCallRecord, setNewCallRecord] = useState(newCallRecordEmptyState);
  const [workerStatus, setWorkerStatus] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const [audioUrl, setAudioUrl] = useState('');
  const [recordingUrl, setRecordingUrl] = useState('');
  const [audioSlideUpVisible, setAudioSlideUpVisible] = useState(false);
  const [name, setName] = useState("");
  const [csvProps, setCsvProps] = useState(null);
  const csvPropsRef = useRef(null);

  // GET Actions
  useEffect(() => {
    setIsLoading(true);
    if (worksiteId) {
      dispatch(getWorksiteCompletedVisits(jwtToken, worksiteId));
      dispatch(getSingleWorksite(jwtToken, worksiteId));
    }
  }, [worksiteId]);

  useEffect(() => {
    if (recordingUrl?.length > 0 && !audioSlideUpVisible) {
      const fetchRecording = async () => {
        try {
          const jwtToken = localStorage.getItem('jwtToken');
          setAudioSlideUpVisible(true);
          const url = await dispatch(getCallRecordingUrl(jwtToken, recordingUrl));
          if (url?.data) {
            const audioBlob = new Blob([url?.data], { type: 'audio/mpeg' });
            const audioObjectUrl = URL.createObjectURL(audioBlob);
            setAudioUrl(audioObjectUrl);
            setRecordingUrl('');
          } else {
            toast.error('Try again later');
            setAudioSlideUpVisible(false);
            setRecordingUrl("");
            setAudioUrl("");
            setName("");
          }
        } catch (error) {
          // toast.error('Try again later');
        }
      };
      fetchRecording();
    }
  }, [recordingUrl, audioSlideUpVisible, dispatch]);


  useEffect(() => {
    const data = callRecords.map((record, i) => ({ ...record, index: i + 1 }));
    setCallRecordsWithIndex(data);
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [callRecords]);

  useEffect(() => {
    if (callRecordsErrorData && Object.keys(callRecordsErrorData)?.length > 0) {
      dispatch(resetWorkSiteCompletedVisitsErrorData());
      setIsLoading(false);
    }
  }, [callRecordsErrorData]);

  // PATCH Actions
  useEffect(() => {
    if (editCallRecordData && Object.keys(editCallRecordData)?.length > 0) {
      setCallRecord(callRecordEmptyState);
      toast.success('Call record data updated successfully');
      setSlideUpVisible(false);
      dispatch(getWorksiteCompletedVisits(jwtToken, worksiteId));
      dispatch(resetEditCallRecordData());
    }
  }, [editCallRecordData]);

  useEffect(() => {
    if (editCallRecordError && Object.keys(editCallRecordError)?.length > 0) {
      toast.error(editCallRecordError.message);
      dispatch(resetEditCallRecordErrorData());
    }
  }, [editCallRecordError]);

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  useEffect(() => {
    const { phoneNumber, workerName, technicianPhoneNo, workOrderNumber } = filters; // Extract phoneNumber and workerName filters

    let filteredData = callRecords;

    // Apply worksiteId filter if provided
    if (worksiteId?.length > 0) {
      filteredData = filteredData.filter(data => data.worksiteId === worksiteId);
    }

    // Apply phoneNumber filter if provided
    if (technicianPhoneNo) {
      filteredData = filteredData.filter(data =>
        data.workerDob?.toLowerCase().includes(technicianPhoneNo.toLowerCase())

      );
    }

    // Apply workerName filter if provided
    // if (workerName) {
    //   filteredData = filteredData.filter(data =>
    //     data.workerName?.toLowerCase().includes(workerName.toLowerCase())
    //   );
    // }

    if (workOrderNumber) {
      filteredData = filteredData.filter(data =>
        data.workOrderNumber?.toLowerCase().includes(workOrderNumber.toLowerCase())
      );
    }

    if (workerStatus && workerStatus !== 'all') {
      filteredData = filteredData.filter(data => data.workerStatus === workerStatus);
    }


    // Add index and update state
    setCallRecordsWithIndex(filteredData.map((record, i) => ({ ...record, index: i + 1 })));
  }, [filters, callRecords, worksiteId, workerStatus]);


  const handleDateRange = (event, picker) => {
    const filteredData = callRecords.filter(data => {
      const createdAtDate = new Date(data.createdAt);
      return createdAtDate >= picker.startDate.toDate() && createdAtDate <= picker.endDate.toDate();
    }).map((record, i) => ({ ...record, index: i + 1 }));

    setCallRecordsWithIndex(filteredData);
    setStartDateRange(picker.startDate.toDate());
    setEndDateRange(picker.endDate.toDate());
  };

  useEffect(() => {
    if (deleteCallRecordData && Object.keys(deleteCallRecordData)?.length > 0) {
      toast.success('Call record data deleted successfully');
      dispatch(getWorksiteCompletedVisits(jwtToken, worksiteId));
      dispatch(resetDeleteCallRecordData());
    }
  }, [deleteCallRecordData]);

  useEffect(() => {
    if (deleteCallRecordError && Object.keys(deleteCallRecordError)?.length > 0) {
      toast.error(deleteCallRecordError.message);
      dispatch(resetDeleteCallRecordErrorData());
    }
  }, [deleteCallRecordError]);

  // Callback to get CSV props from ToolkitProvider
  // useEffect(() => {
  //   if (csvPropsRef.current) {
  //     setCsvProps(csvPropsRef.current);
  //   }
  // }, [csvPropsRef.current]);

  //slide-up modal
  const [slideUpVisible, setSlideUpVisible] = useState(false);
  const [slideUpVisibleForDelete, setSlideUpVisibleForDelete] = useState(false);
  const [slideUpModalSize, setSlideUpModalSize] = useState([false, true, false,]);
  const [slideUpWidth, setSlideUpWidth] = useState("500");
  useEffect(() => {
    if (slideUpModalSize[0]) setSlideUpWidth("600");
    if (slideUpModalSize[1]) setSlideUpWidth("700");
    if (slideUpModalSize[2]) setSlideUpWidth("300");
  }, [slideUpModalSize]);

  // Create call records modal
  const [slideUpVisibleForCreate, setSlideUpVisibleForCreate] = useState(false);
  const [slideUpVisibleForViewData, setSlideUpVisibleForViewData] = useState(false);
  const [viewData, setViewData] = useState(false);
  const [exportCompletedVisitsData, setExportCompletedVisitsData] = useState()

  const handleInputChange = (e, selectOption = null) => {
    if (selectOption) {
      setCallRecord((prevCallRecord) => ({
        ...prevCallRecord,
        [e.name]: selectOption
      }));
    } else {
      const { name, value } = e.target;
      setCallRecord((prevCallRecord) => ({
        ...prevCallRecord,
        [name]: value
      }));
    }
  };

  const handleEditClick = (row) => {
    setCurrentCallRecordId(row.id)
    setSlideUpVisible(true)
    setCallRecord({
      worksiteId: row?.worksiteId,
      phoneNumber: row?.phoneNumber,
      workerName: row?.workerName,
      workerDob: row?.workerDob,
      workerStatus: row?.workerStatus,
      workOrderNumber: row?.workOrderNumber,
      checkIn: row?.checkIn ? new Date(row?.checkIn) : '',
      checkOut: row?.checkOut ? new Date(row?.checkOut) : '',
    });
  }

  const handleDeleteClick = (row) => {
    setCurrentCallRecordId(row.id)
    setSlideUpVisibleForDelete(true)
  }

  const handleClickUrlOne = (row) => {
    if (!audioSlideUpVisible) {
      setRecordingUrl(row.checkInRecordingUrl);
      setName(row.workerName);
    }
  };

  const handleClickUrlTwo = (row) => {
    if (!audioSlideUpVisible) {
      setRecordingUrl(row.checkOutRecordingUrl);
      setName(row.workerName);
    }
  };

  const closeModal = () => {
    setName('');
    setAudioUrl('');
    setAudioSlideUpVisible(false);
  };

  const handleViewClick = (row) => {
    setViewData(row);
    setSlideUpVisibleForViewData(true)
  };

  const handleEdit = () => {
    const missingFields = [];
    if (!callRecord.phoneNumber) missingFields.push('Phone number');
    if (!callRecord.workerName) missingFields.push('Worker name');
    if (!callRecord.workerDob) missingFields.push('Technician phone no');
    if (!callRecord.workOrderNumber) missingFields.push('Work order number');
    if (!callRecord.checkIn) missingFields.push('Check in');
    if (!callRecord.workerStatus) missingFields.push('Worker status');
    if (!callRecord.checkOut) missingFields.push('Check out');

    if (missingFields.length > 0) {
      toast.error('Please fill all required fields')
    }

    if (missingFields?.length === 0) {
      dispatch(editCallRecordByWorksite(jwtToken, currentCallRecordId, callRecord));
    }
  };

  const handleDelete = () => {
    if (currentCallRecordId) {
      dispatch(deleteCallRecordByWorksite(jwtToken, currentCallRecordId));
      setSlideUpVisibleForDelete(false);
    }
  };

  const tableColumns = [
    // {
    //   dataField: 'index',
    //   text: '#',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    // },
    // {
    //   dataField: 'createdAt',
    //   text: 'Date',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   sort: true,
    //   formatter: (cell) => {
    //     if (cell) {
    //       return new Date(cell).toLocaleString('en-US', {
    //         year: 'numeric',
    //         month: 'numeric',
    //         day: 'numeric',
    //         // hour: '2-digit',
    //         // minute: '2-digit',
    //         // second: '2-digit',
    //         // hour12: true,
    //       });
    //     } else {
    //       return '';
    //     }
    //   },
    // },
    {
      dataField: 'workOrderNumber',
      text: 'Work Order No',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      formatter: (cell, row) => {
        return (
          <Link to={`/worksite/work-order/${row.workOrderNumber}`}>
            {row.workOrderNumber}
          </Link>
        );
      },
      sortFunc: (a, b, order) => {
      const workOrderA = BigInt(a.replace(/\D/g, ''));  
      const workOrderB = BigInt(b.replace(/\D/g, ''));

      if (order === 'asc') {
        return workOrderA > workOrderB ? 1 : -1;  
      }
      if (order === 'desc') {
      return workOrderA < workOrderB ? 1 : -1; 
      }
      },
    },
    {
      dataField: 'checkIn',
      text: 'Date',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      formatter: (cell, row) => {
        if (cell) {
          return (
            <>
              <span>
                {new Date(cell).toLocaleString('en-US', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                })}
              </span>
            </>
          );
        } else {
          return '';
        }
      },
      sortFunc: (a, b, order) => {
      const dateA = a ? new Date(a).getTime() : 0;
      const dateB = b ? new Date(b).getTime() : 0;

      if (order === 'asc') {
        return dateA - dateB;
      }
      if (order === 'desc') {
        return dateB - dateA;
      }
    }
    },
    {
      dataField: 'checkIn',
      text: 'Check In',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      formatter: (cell, row) => {
        if (cell) {
          return (
            <>
              <span>
                {/* {new Date(cell).toLocaleString('en-US', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                })}
                <br /> */}
                {new Date(cell).toLocaleString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: true,
                })}
              </span>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  flexDirection: "row",
                  cursor: 'pointer',
                  marginTop: "7px"
                }}
                onClick={() => handleClickUrlOne(row)}
              >
                <div>
                  {row?.checkInRecordingUrl && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#007bff"
                      className="bi bi-play-circle"
                      viewBox="0 0 16 16"
                      style={{ marginRight: '5px' }}
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                    </svg>
                  )}
                </div>
                <div>{row?.checkInCallDuration ? `${row?.checkInCallDuration} sec` : ''}</div>
              </div>
            </>
          );
        } else {
          return '';
        }
      },
      sortFunc: (a, b, order) => {
        const dateA = new Date(a).getTime();
        const dateB = new Date(b).getTime();
        if (order === 'asc') {
          return dateA - dateB;
        }
        if (order === 'desc') {
        return dateB - dateA;
        }
      }
    },
    {
      dataField: 'checkOut',
      text: 'Check Out',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      formatter: (cell, row) => {
        if (cell) {
          return (
            <>
              <span>
                {/* {new Date(cell).toLocaleString('en-US', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                })}
                <br /> */}
                {new Date(cell).toLocaleString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: true,
                })}
              </span>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  flexDirection: "row",
                  cursor: 'pointer',
                  marginTop: "7px"
                }}
                onClick={() => handleClickUrlTwo(row)}
              >
                <div>
                  {row?.checkOutRecordingUrl && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#007bff"
                      className="bi bi-play-circle"
                      viewBox="0 0 16 16"
                      style={{ marginRight: '5px' }}
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                    </svg>
                  )}
                </div>
                <div>{row?.checkOutCallDuration ? `${row?.checkOutCallDuration} sec` : ''}</div>
              </div>
            </>
          );
        } else {
          return '';
        }
      },
      sortFunc: (a, b, order) => {
        const dateA = new Date(a).getTime();
        const dateB = new Date(b).getTime();
        if (order === 'asc') {
          return dateA - dateB;
        }
        if(order === 'desc') {
          return dateB - dateA;
        }
      }
    },
    {
      dataField: 'workerName',
      text: 'Check-in Technician Name',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        const nameA = a ? a.trim().toLowerCase() : '';
        const nameB = b ? b.trim().toLowerCase() : '';

        console.log(a);
        console.log(b);

        if (order === 'asc') {
          return nameA.localeCompare(nameB);
        }
        if(order === 'desc') {
          return nameB.localeCompare(nameA);
        }      
      }
    },
    {
      dataField: 'checkOutWorkerName',
      text: 'Check-out Technician Name',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        const nameA = a ? a.trim().toLowerCase() : '';
        const nameB = b ? b.trim().toLowerCase() : '';

        if (order === 'asc') {
          return nameA.localeCompare(nameB);
        }
        if(order === 'desc') {
          return nameB.localeCompare(nameA);
        }
      }
    },
    {
      dataField: 'workerDob',
      text: 'Technician Phone No',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
      const phoneA = BigInt(a.replace(/\D/g, ''));
      const phoneB = BigInt(b.replace(/\D/g, ''));

      if (order === 'asc') {
        return phoneA > phoneB ? 1 : -1;
      }
      if(order === 'desc') {
        return phoneA < phoneB ? 1 : -1;
      }
    },
    },
    // {
    //   dataField: 'phoneNumber',
    //   text: 'Phone No',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   //   sort: true,
    // },
    {
      dataField: 'workerStatus',
      text: 'Status',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      formatter: (cell) => {
        if (typeof cell === 'string') {
          // Define the status to className map
          const statusClassMap = {
            'Completed': 'badge bg-success text-white fw-lighter p-2',
            'Additional Work Found': 'badge bg-primary text-white fw-lighter p-2',
            'Requires Part': 'badge bg-secondary fw-lighter p-2',
            'Requires Follow-up': 'badge bg-secondary fw-lighter p-2',
            'Requires Authorization': 'badge bg-danger text-white fw-lighter p-2',
          };

          // Get the capitalized status
          const capitalizedStatus = cell
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

          // Determine the appropriate className based on the status
          const className = statusClassMap[capitalizedStatus] || 'badge bg-dark'; // Default class
          return <span className={className}>{capitalizedStatus}</span>;
        }
        return cell;
      },
    },
    {
      dataField: 'workHours',
      text: 'Work Hours',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      formatter: (cell) => {
        if (typeof cell !== 'string' || !cell.includes('.')) {
          return '';
        }
        const [hours, minutes] = cell.split('.');
        return `${hours}Hr ${minutes}Min`;
      },
      sortFunc: (a, b, order) => {
        const hourA = a;
        const hourB = b;
        if (order === 'asc') {
          return hourA - hourB;
        }
        if(order === 'desc') {
          return hourB - hourA;
        }
      },
    },
    {
      dataField: 'manualEntry',
      text: 'Manual Entry',
      csvExport: true,
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      formatter: (cell) => {
        if (!cell) return '';
        if (!cell?.role) return '-';

        return (
          <>
            {`${cell.role.charAt(0).toUpperCase() + cell.role.slice(1).toLowerCase()}`}
            {cell.editedBy?.name ? ` - ${cell.editedBy.name}` : ''}
            <br />
            {cell.editedAt ? `(${formateDate(cell.editedAt)})` : ''}
          </>
        );
      },
      csvFormatter: (cell) => {
        if (!cell) return '';
        if (!cell?.role) return '-';
        return `${cell.role.charAt(0).toUpperCase() + cell.role.slice(1).toLowerCase()} - ${cell.editedBy.name} (${formateDate(cell.editedAt)})`;
      },
      sortFunc: (a, b, order) => {
        // Add logs to debug
        // console.log('A:', a);
        // console.log('B:', b);
    
        // Test with simplified sorting by editedAt for now
        const getEditedAt = (entry) => {
          return entry?.editedAt ? new Date(entry.editedAt) : new Date(0); // Fallback to a very old date if not present
        };
    
        const dateA = getEditedAt(a);
        const dateB = getEditedAt(b);
    
        console.log('Comparing dates:', dateA, dateB); // Debugging
    
        // return dateA - dateB; // Sort by date

        if (order === 'asc') {
          return dateA - dateB; // Ascending order
        }

        if(order === 'desc') {
          return dateB - dateA; // Descending order
        }
      }
    },
    {
      text: 'Actions',
      headerStyle: { backgroundColor: '#f0f0f073' },
      csvExport: false,
      formatter: (cell, row) => (
        <div>
          <Button
            style={{ backgroundColor: 'green', borderColor: 'green', color: 'white' }}
            type="primary"
            utilclass="m-r-5"
            iconposition="center"
            onClick={() => handleViewClick(row)}
          >
            <i className="pg-icon"><RiShareBoxFill color='white' /></i>
          </Button>
          <Button
            type="primary"
            utilclass="m-r-5"
            iconposition="center"
            onClick={() => handleEditClick(row)}
          >
            <i className="pg-icon">edit</i>
            {/* <span className="">Edit</span> */}
          </Button>
          <Button
            type="danger"
            iconposition="center"
            onClick={() => handleDeleteClick(row)}
          >
            <i className="pg-icon">trash</i>
            {/* Delete */}
          </Button>
        </div>
      ),
    },
  ];

  const formateDate = (newDate) => {
    const originalDate = new Date(newDate);
    const options = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      // timeZone: 'UTC'
    };

    const formattedDate = originalDate.toLocaleString('en-US', options)
      .replace(',', '');
    return formattedDate;
  }

  useEffect(() => {
    setExportCompletedVisitsData(nullToEmpty(callRecordsWithIndex))
  }, [callRecordsWithIndex])

  return (
    <div className="page-container">
      <div className="page-content-wrapper">
        <div className="content">
          <PageContainer className="container-fluid container-fixed-lg bg-white">
            <div className="card card-transparent">
              <div className="card-header d-flex flex-column justify-content-between">
                <div className="d-flex flex-row justify-content-between w-100 mb-4">
                  <h3 className="text-primary no-margin text-uppercase">Completed Visits</h3>
                  <div>
                    {csvProps && (
                      <ExportCSVButton   {...csvProps}><i className="pg-icon m-r-10"><FiShare size={'15'} /></i> Export CSV</ExportCSVButton>
                    )}
                  </div>
                </div>

                <div className="stats row">
                  <div className="col-lg-2 col-md-4 col-sm-6">
                    <Card className="d-flex align-items-center justify-content-center text-center py-2 bg-primary text-white">
                      <CardBody className="p-0">
                        <h5 className='text-uppercase'>Work Hours</h5>
                        <h5>{calculateTotalWorkHours(callRecordsWithIndex)}</h5>
                      </CardBody>
                    </Card>
                  </div>

                  <div className="col-lg-2 col-md-4 col-sm-6">
                    <Card className="d-flex align-items-center justify-content-center text-center py-2 text-white" style={{ backgroundColor: 'gray', borderColor: 'gray' }}>
                      <CardBody className="p-0">
                        <h5 className='text-uppercase'>Workers</h5>
                        <h5>{calculateTotalWorkers(callRecordsWithIndex)}</h5>
                      </CardBody>
                    </Card>
                  </div>

                  <div className="col-lg-4 col-md-8 col-sm-12">
                    <Card className="px-4 py-2 text-dark" style={{ border: '1px solid grey' }}>
                      <CardBody className="p-0 d-flex justify-content-between">
                        <div>
                          <p className='text-uppercase mb-2'>Store Name</p>
                          <p className='mb-1'>{worksiteManagerData.storeName}</p>
                        </div>

                        <div>
                          <p className='text-uppercase mb-2'>Store Address</p>
                          <p className='mb-1'>{worksiteManagerData.address}, {worksiteManagerData.address2}</p>
                          <p className='mb-1'>{worksiteManagerData.city}, {worksiteManagerData.state}</p>
                          <p className='mb-1'>{worksiteManagerData.zip}, {worksiteManagerData.country}</p>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>

                <div className="DTTT btn-group d-flex align-items-center">
                  <div className="input-group m-r-15">
                    <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                    <input type="text" className="form-control p-l-10" placeholder="Search by work order number" name='workOrderNumber' value={filters.workOrderNumber} onChange={handleSearchChange} />
                  </div>

                  <div className="input-group m-r-15">
                    <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                    <input type="text" className="form-control p-l-10" placeholder="Search by technician phone number" value={filters.technicianPhoneNo} name='technicianPhoneNo' onChange={handleSearchChange} />
                  </div>

                  <div className="input-group m-r-15">
                    <GroupSelect
                      placeholder='Status'
                      inputId='worksiteId'
                      value={workerStatus}
                      options={workerStatusFilterOptions}
                      onChange={(selectedOption) => setWorkerStatus(selectedOption?.value)}
                    />
                  </div>

                  <DateRangePicker startDate={startDateRange} endDate={endDateRange} showTimeSelect onApply={handleDateRange}>
                    <div className="input-group">
                      <span className="input-group-text input-date-icon-pre">
                        <i className="pg-icon">calendar</i>
                      </span>
                      <input type="text" name="reservation" id="daterangepicker" onChange={() => { }} className="form-control col-md-19"
                        value={`${moment(startDateRange).format("MM/DD/YYYY h:mm A")} - ${moment(endDateRange).format("MM/DD/YYYY h:mm A")}`}
                      />
                    </div>
                  </DateRangePicker>

                  {slideUpVisibleForDelete ?
                    <SlideUpModal visible={slideUpVisibleForDelete} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisibleForDelete(false)}>
                      <div className="modal-content-wrapper">
                        <div className="modal-content">
                          <div className="modal-top">
                            <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisibleForDelete(false)} data-dismiss="modal" aria-hidden="true">
                              <i className="pg-icon">close</i>
                            </button>
                            <h5>Are you sure you want to delete?</h5>
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-md-6">
                                <button aria-label="" type="button" className="btn btn-secondary btn-block" onClick={() => setSlideUpVisibleForDelete(false)}>
                                  Cancel
                                </button>
                              </div>
                              <div className="col-md-6">
                                <button aria-label="" type="button" className="btn btn-danger btn-block" onClick={handleDelete}>
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SlideUpModal> : null}

                  {slideUpVisible ?
                    <SlideUpModal visible={slideUpVisible} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisible(false)}>
                      <div className="modal-content-wrapper">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5>Edit Call Record</h5>
                            <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisible(false)} data-dismiss="modal" aria-hidden="true">
                              <i className="pg-icon">close</i>
                            </button>
                          </div>
                          <div className="modal-body p-5">
                            <form role="form">
                              <div class="input-group flex-column">
                                <div className="row">
                                  <div className="col-md-6">
                                    <label for='phoneNumber'>Phone Number</label>
                                    <input type="text" className="form-control w-100" placeholder='Update Phone Number' value={callRecord.phoneNumber} onChange={handleInputChange} name='phoneNumber' id='phoneNumber' />
                                  </div>
                                  <div className="col-md-6">
                                    <label for='workerName'>Worker Name</label>
                                    <input type="text" className="form-control w-100" placeholder='Update Worker Name' value={callRecord.workerName} onChange={handleInputChange} name='workerName' id='workerName' />
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-4">
                                    <label for='workerDob' className='mt-3'>Technician Phone No</label>
                                    <input type="text" className="form-control w-100" placeholder='Update Technician Phone No' value={callRecord.workerDob} onChange={handleInputChange} name='workerDob' id='workerDob' />
                                  </div>

                                  <div className="col-md-4">
                                    <label htmlFor='workerStatus' className='mt-3'>Worker Status</label>
                                    <GroupSelect
                                      inputId='workerStatus'
                                      value={callRecord.workerStatus}
                                      options={workerStatusOptions}
                                      onChange={(selectedOption) => handleInputChange({ name: 'workerStatus' }, selectedOption?.value)}
                                    />
                                  </div>

                                  <div className="col-md-4">
                                    <label for='workOrderNumber' className='mt-3'>Work Order Number</label>
                                    <input type="text" className="form-control w-100" placeholder='Update Work Order Number' value={callRecord.workOrderNumber} onChange={handleInputChange} name='workOrderNumber' id='workOrderNumber' />
                                  </div>
                                </div>

                                <label for='checkIn' className='mt-3'>Check In<span style={{ color: 'red' }}>*</span></label>
                                <DatePicker
                                  onChange={(selectedOption) => handleInputChange({ name: 'checkIn' }, selectedOption)}
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  timeIntervals={1}
                                  timeCaption="Time"
                                  dateFormat="MMMM d, yyyy h:mm aa"
                                  placeholderText="Select date and time"
                                  className="form-control w-100"
                                  selected={callRecord.checkIn}
                                  name="checkIn"
                                  id="checkIn"
                                />

                                <label for='checkOut' className='mt-3'>Check Out<span style={{ color: 'red' }}>*</span></label>
                                <DatePicker
                                  onChange={(selectedOption) => handleInputChange({ name: 'checkOut' }, selectedOption)}
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  timeIntervals={1}
                                  timeCaption="Time"
                                  dateFormat="MMMM d, yyyy h:mm aa"
                                  placeholderText="Select date and time"
                                  className="form-control w-100"
                                  selected={callRecord.checkOut}
                                  name='checkOut'
                                  id='checkOut'
                                />
                              </div>

                            </form>
                          </div>
                          <div className='modal-footer'>
                            <button aria-label="" type="button" className="btn btn-primary" onClick={handleEdit}>
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </SlideUpModal>
                    : null
                  }

                  {audioSlideUpVisible ? (
                    <SlideUpModal visible={audioSlideUpVisible} width={'400'} effect="fadeInUp" onClickAway={() => closeModal()}>
                      <div className="modal-content-wrapper">
                        <div className="modal-content">
                          <div className="modal-top">
                            <button
                              aria-label=""
                              type="button"
                              className="close"
                              onClick={() => closeModal()}
                              aria-hidden="true"
                            >
                              <i className="pg-icon">close</i>
                            </button>
                            <h4 className="text-primary no-margin text-uppercase text-center">{name}</h4>
                          </div>
                          {audioUrl?.length > 0 ? (
                            <div className="modal-body">
                              <form role="form">
                                <div className="form-group-attached">
                                  <div className="row justify-content-center">
                                    <audio controls style={{ margin: "0", height: '40px' }}>
                                      <source src={audioUrl} type="audio/mpeg" />
                                    </audio>
                                  </div>
                                </div>
                              </form>
                            </div>
                          ) : (
                            <div className="modal-body">
                              <div className="row justify-content-center">
                                <div class="spinner-grow" role="status">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </SlideUpModal>
                  )
                    : null
                  }

                  {slideUpVisibleForViewData ?
                    <SlideUpModal visible={slideUpVisibleForViewData} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisibleForViewData(false)}>
                      <div className="modal-content-wrapper">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5>Call Record Details</h5>
                            <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisibleForViewData(false)} data-dismiss="modal" aria-hidden="true">
                              <i className="pg-icon">close</i>
                            </button>
                          </div>
                          <div className="modal-body py-3 h100vh">
                            <div className="row">

                              <div className="col-md-6">
                                {/* <p><strong>ID:</strong> {viewData.id}</p> */}
                                <div className='social-card share w-100 mb-3'>
                                  <div class="pgn-message card-header">
                                    <div>
                                      <p>Worker Name</p>
                                      {viewData.workerName ?
                                        <p class="bold mb-0">{viewData.workerName}</p> :
                                        <p class="bold mb-0 ml-1">{"-"}</p>
                                      }
                                    </div>
                                  </div>
                                </div>

                                <div className='social-card share w-100 mb-3'>
                                  <div class="pgn-message card-header">
                                    <div>
                                      <p>Phone Number</p>
                                      {viewData.phoneNumber ?
                                        <p class="bold mb-0">{viewData.phoneNumber}</p> :
                                        <p class="bold mb-0 ml-1">{"-"}</p>
                                      }
                                    </div>
                                  </div>
                                </div>

                                <div className='social-card share w-100 mb-3'>
                                  <div class="pgn-message card-header">
                                    <div>
                                      <p>Check In</p>
                                      {viewData.checkIn ?
                                        <p class="bold mb-0">{formateDate(viewData.checkIn)}</p> :
                                        <p class="bold mb-0 ml-1">{"-"}</p>
                                      }
                                    </div>
                                  </div>
                                </div>

                                <div className='social-card share w-100 mb-3'>
                                  <div class="pgn-message card-header">
                                    <div>
                                      <p>Check In Call Duration</p>
                                      {viewData.checkInCallDuration ?
                                        <p class="bold mb-0">{`${viewData?.checkInCallDuration} sec` || 'N/A'}</p> :
                                        <p class="bold mb-0 ml-1">{"-"}</p>
                                      }
                                    </div>
                                  </div>
                                </div>

                                <div className='social-card share w-100 mb-3'>
                                  <div class="pgn-message card-header">
                                    <div>
                                      <p>Created At</p>
                                      <p class="bold mb-0">{formateDate(viewData?.createdAt)}</p>
                                    </div>
                                  </div>
                                </div>

                                <div className='social-card share w-100 mb-3'>
                                  <div class="pgn-message card-header">
                                    <div>
                                      <p>Worker Status</p>
                                      {viewData.workerStatus ?
                                        <p class="bold mb-0">{viewData.workerStatus}</p> :
                                        <p class="bold mb-0 ml-1">{"-"}</p>
                                      }
                                    </div>
                                  </div>
                                </div>

                              </div>

                              <div className="col-md-6">
                                <div className='social-card share w-100 mb-3'>
                                  <div class="pgn-message card-header">
                                    <div>
                                      <p>Work Order Number</p>
                                      {viewData.workOrderNumber ?
                                        <p class="bold mb-0">{viewData.workOrderNumber}</p> :
                                        <p class="bold mb-0 ml-1">{"-"}</p>
                                      }
                                    </div>
                                  </div>
                                </div>

                                <div className='social-card share w-100 mb-3'>
                                  <div class="pgn-message card-header">
                                    <div>
                                      <p>Technician Phone No</p>
                                      {viewData.workerDob ?
                                        <p class="bold mb-0">{viewData.workerDob}</p> :
                                        <p class="bold mb-0 ml-1">{"-"}</p>
                                      }
                                    </div>
                                  </div>
                                </div>

                                <div className='social-card share w-100 mb-3'>
                                  <div class="pgn-message card-header">
                                    <div>
                                      <p>Check Out</p>
                                      {viewData.checkOut ?
                                        <p class="bold mb-0">{formateDate(viewData.checkOut)}</p> :
                                        <p class="bold mb-0 ml-1">{"-"}</p>
                                      }
                                    </div>
                                  </div>
                                </div>

                                <div className='social-card share w-100 mb-3'>
                                  <div class="pgn-message card-header">
                                    <div>
                                      <p>Check Out Call Duration</p>
                                      {viewData.checkOutCallDuration ?
                                        <p class="bold mb-0">{`${viewData?.checkOutCallDuration} sec` || 'N/A'}</p> :
                                        <p class="bold mb-0 ml-1">{"-"}</p>
                                      }
                                    </div>
                                  </div>
                                </div>

                                <div className='social-card share w-100 mb-3'>
                                  <div class="pgn-message card-header">
                                    <div>
                                      <p>Updated At</p>
                                      <p class="bold mb-0">{formateDate(viewData?.updatedAt)}</p>
                                    </div>
                                  </div>
                                </div>

                                {viewData && viewData?.manualEntry?.role && <div className='social-card share w-100 mb-3'>
                                  <div className="pgn-message card-header">
                                    <div>
                                      <p>Manual Entry</p>
                                      <p className="bold mb-0"><span className='text-capitalize'>{viewData?.manualEntry?.role}</span> - {viewData?.manualEntry?.editedBy?.name} <br /> ({formateDate(viewData?.manualEntry?.editedAt)})</p>
                                    </div>
                                  </div>
                                </div>}

                                {/* <p><strong>Check In Recording URL:</strong> {viewData?.checkInRecordingUrl || 'N/A'}</p> */}
                                {/* <p><strong>Check Out Recording URL:</strong> {viewData?.checkOutRecordingUrl || 'N/A'}</p> */}
                                {/* <p><strong>Check In Call SID:</strong> {viewData?.checkInCallSID || 'N/A'}</p>
                                <p><strong>Check Out Call SID:</strong> {viewData?.checkOutCallSID || 'N/A'}</p>
                                <p><strong>Check In Recording SID:</strong> {viewData?.checkInRecordingSID || 'N/A'}</p>
                                <p><strong>Check Out Recording SID:</strong> {viewData?.checkOutRecordingSID || 'N/A'}</p> */}
                                {/* <p><strong>Worksite:</strong> {viewData?.worksiteId}</p> */}

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SlideUpModal>
                    : null
                  }
                </div>
              </div>


              {!isLoading ? <div className="card-body custable">
                {callRecordsWithIndex?.length > 0 ? (
                  <ToolkitProvider
                    keyField="id"
                    data={exportCompletedVisitsData}
                    columns={tableColumns}
                    exportCSV={{
                      fileName: 'completedVisits-data.csv',
                      ignoreHeader: false,
                      noAutoBOM: false,
                    }}
                  >
                    {(props) => {
                      csvPropsRef.current = props.csvProps;
                      if (!csvProps) {
                        setCsvProps(props.csvProps);
                      }
                      return (
                        <React.Fragment>
                          <BootstrapTable
                            {...props.baseProps}
                            hover={true}
                            bootstrap4
                            bordered={false}
                            condensed={true}
                            striped={true}
                            pagination={paginationFactory()}
                          // cellEdit={cellEditFactory({
                          //   mode: 'click',
                          //   blurToSave: true,
                          // })}
                          />
                        </React.Fragment>
                      )
                    }}
                  </ToolkitProvider>
                ) : (
                  <NoData />
                )}
              </div> : <Loader />}
            </div>
          </PageContainer>
        </div>
      </div>
    </div>
  );
};

export default CompletedVisits;
