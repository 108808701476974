import React, { useState, useEffect, useRef } from 'react';
import ToolkitProvider, {CSVExport} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import PageContainer from '../../UIElements/Containers';
import GroupSelect from "../../Forms/Elements/GroupSelect";
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import "bootstrap-daterangepicker/daterangepicker.css";
import { getCorporateVisitsSummary, getWorkSitesForCorporate, resetGetCallRecordErrorData } from '../../../api-actions/corporateDataAction';
import 'react-datepicker/dist/react-datepicker.css';
import { FiShare } from 'react-icons/fi';
import Loader from '../../Loader/Loader';
import NoData from '../../NoData/NoData';
import { Link } from 'react-router-dom';

const VisitsSummary = () => {
  const { ExportCSVButton } = CSVExport;
  const jwtToken = localStorage.getItem('jwtToken');

  let endDate = new Date();
  endDate.setDate(endDate.getDate() + 30);

  const [workerStatus, setWorkerStatus] = useState('');

  const dispatch = useDispatch();

  const userId = useSelector((state) => state.AdminAuthData.userId);
  const VisitsSummary = useSelector((state) => state.CorporateData.getCorporateVisitsSummaryData);
  const callRecordsErrorData = useSelector((state) => state.CorporateData.getCorporateCompletedVisitsError);
  const workSitesForCorporateData = useSelector((state) => state.CorporateData.getWorkSitesForCorporateData);
  const workSitesForCorporateError = useSelector((state) => state.CorporateData.getWorkSitesForCorporateError);

  const [VisitsSummaryWithIndex, setVisitsSummaryWithIndex] = useState([]);
  const [CallRecordsWithIndex, setCallRecordsWithIndex] = useState([]);
  const [worksiteOptionsForFilter, setWorksiteOptionsForFilter] = useState([]);
  const [worksiteOptions, setWorksiteOptions] = useState([]);
  const [worksiteId, setWorksiteId] = useState('');
  const workerStatusFilterOptions = [{ value: 'all', label: 'All' }, { value: 'completed', label: 'Completed' }, { value: 'additional work found', label: 'Additional Work Found' }, { value: 'requires part', label: 'Requires Part' }, { value: 'requires follow-up', label: 'Requires Follow-up' }, { value: 'requires authorization', label: 'Requires Authorization' }]
  const workerStatusOptions = [{ value: 'completed', label: 'Completed' }, { value: 'additional work found', label: 'Additional Work Found' }, { value: 'requires part', label: 'Requires Part' }, { value: 'requires follow-up', label: 'Requires Follow-up' }, { value: 'requires authorization', label: 'Requires Authorization' }]
  const [isLoading, setIsLoading] = useState(true);

  // Create call records modal
  const [csvProps, setCsvProps] = useState(null);
  const csvPropsRef = useRef(null);

  const [filters, setFilters] = useState({
    storeNumber: '',
    workOrderNumber: '',
  });

  // GET Actions
  useEffect(() => {
    setIsLoading(true)
    if (userId) {
      dispatch(getCorporateVisitsSummary(jwtToken,userId));
      dispatch(getWorkSitesForCorporate(jwtToken, userId));
    }
  }, [userId]);

  // useEffect(() => {
  //   if (VisitsSummary) {
  //     const data = VisitsSummary.map((record, i) => ({
  //       ...record,
  //       index: i + 1,
  //       workHours: parseFloat(record.workHours) || 0,
  //     }));
  
  //     const aggregatedData = data.reduce((acc, current) => {
  //       const foundIndex = acc.findIndex(item => item.workOrderNumber === current.workOrderNumber);
  //       if (foundIndex >= 0) {
  //         acc[foundIndex].workHours += current.workHours;
  //       } else {
  //         acc.push({ ...current });
  //       }
  //       return acc;
  //     }, []);
  
  //     let finalAggregatedData = aggregatedData.map((record) => ({
  //       ...record,
  //       workHours: record.workHours.toFixed(2),
  //     }));
  
  //     // Apply search filters
  //     if (filters.storeNumber) {
  //       finalAggregatedData = finalAggregatedData.filter((record) =>
  //         record.storeNumber.toLowerCase().includes(filters.storeNumber.toLowerCase())
  //       );
  //     }
  
  //     if (filters.workOrderNumber) {
  //       finalAggregatedData = finalAggregatedData.filter((record) =>
  //         record.workOrderNumber.toLowerCase().includes(filters.workOrderNumber.toLowerCase())
  //       );
  //     }
  
  //     // Apply worksite filter
  //     if (worksiteId?.length > 0) {
  //       const filteredData = finalAggregatedData
  //         .filter((record) => record?.worksiteId === worksiteId)
  //         .map((record, i) => ({ ...record, index: i + 1 }));
  //       setVisitsSummaryWithIndex(filteredData);
  //     } else {
  //       setVisitsSummaryWithIndex(finalAggregatedData);
  //     }
  //   }
  // }, [VisitsSummary, worksiteId, filters.storeNumber, filters.workOrderNumber]);
  
  useEffect(() => {
    if (VisitsSummary) {
      const data = VisitsSummary.map((record, i) => ({
        ...record,
        index: i + 1,
        workHours: parseFloat(record.workHours) || 0,
      }));
  
      // Aggregate data based on both workOrderNumber and storeNumber
      const aggregatedData = data.reduce((acc, current) => {
        // Find an entry with the same workOrderNumber and storeNumber
        const foundIndex = acc.findIndex(
          item =>
            item.workOrderNumber === current.workOrderNumber &&
            item.storeNumber === current.storeNumber
        );
  
        if (foundIndex >= 0) {
          acc[foundIndex].workHours += current.workHours;
        } else {
          acc.push({ ...current });
        }
        return acc;
      }, []);
  
      let finalAggregatedData = aggregatedData.map((record) => ({
        ...record,
        workHours: record.workHours.toFixed(2),
      }));
  
      // Apply search filters
      if (filters.storeNumber) {
        finalAggregatedData = finalAggregatedData.filter((record) =>
          record.storeNumber.toLowerCase().includes(filters.storeNumber.toLowerCase())
        );
      }
  
      if (filters.workOrderNumber) {
        finalAggregatedData = finalAggregatedData.filter((record) =>
          record.workOrderNumber.toLowerCase().includes(filters.workOrderNumber.toLowerCase())
        );
      }
  
      // Apply worksite filter
      if (worksiteId?.length > 0) {
        const filteredData = finalAggregatedData
          .filter((record) => record?.worksiteId === worksiteId)
          .map((record, i) => ({ ...record, index: i + 1 }));
        setVisitsSummaryWithIndex(filteredData);
      } else {
        setVisitsSummaryWithIndex(finalAggregatedData);
      }
    }
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [VisitsSummary, worksiteId, filters.storeNumber, filters.workOrderNumber]);
  
  useEffect(() => {
    if (workSitesForCorporateData) {
      setWorksiteOptions([]);
      setWorksiteOptionsForFilter([]);
      const worksiteData = workSitesForCorporateData?.map((record) => ({
        value: record?.id,
        label: record?.storeName,
      }));

      setWorksiteOptions(Array.from(new Map(worksiteData.map(item => [item.value, item])).values()))

      const uniqueArray = Array.from(new Map(worksiteData.map(item => [item.value, item])).values());
      if (uniqueArray?.length > 0) {
        uniqueArray.unshift({ value: '', label: 'All Stores' });
      }
      setWorksiteOptionsForFilter(uniqueArray);
    }

  }, [workSitesForCorporateData]);

  useEffect(() => {
    if (callRecordsErrorData && Object.keys(callRecordsErrorData)?.length > 0) {
      dispatch(resetGetCallRecordErrorData());
      setIsLoading(false)
    }
  }, [callRecordsErrorData]);

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const tableColumns = [
    // {
    //   dataField: 'index',
    //   text: '#',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    // },
    {
      dataField: 'workOrderNumber',
      text: 'Worker Order No',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      formatter: (cell, row) => {
        return (
          <Link to={`/corporate/work-order/${row.worksiteId}/${row.workOrderNumber}`}>
            {row.workOrderNumber}
          </Link>
        );
      },
    },
    {
      dataField: 'workHours',
      text: 'Total Working Hours',
      headerStyle: { backgroundColor: '#f0f0f073' },
      formatter: (cell) => {
        if (typeof cell !== 'string' || !cell.includes('.')) {
          return '';
        }
        const [hours, minutes] = cell.split('.');
        return `${hours}Hr ${minutes}Min`;
      },
    },
    // {
    //   dataField: 'workerStatus',
    //   text: 'Status',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   sort: true,
    // },
    {
      dataField: 'workerStatus',
      text: 'Status',
      headerStyle: { backgroundColor: '#f0f0f073' },
      formatter: (cell) => {
        if (typeof cell === 'string') {
          // Define the status to className map
          const statusClassMap = {
            'Completed': 'badge bg-success text-white fw-lighter p-2',
            'Additional Work Found': 'badge bg-primary text-white fw-lighter p-2',
            'Requires Part': 'badge bg-secondary fw-lighter p-2',
            'Requires Follow-up': 'badge bg-secondary fw-lighter p-2',
            'Requires Authorization': 'badge bg-danger text-white fw-lighter p-2',
          };

          // Get the capitalized status
          const capitalizedStatus = cell
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

          // Determine the appropriate className based on the status
          const className = statusClassMap[capitalizedStatus] || 'badge bg-dark'; // Default class
          return <span className={className}>{capitalizedStatus}</span>;
        }
        return cell;
      },
      // sort: true,
    },
    {
      dataField: 'storeNumber',
      text: 'Store Number',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: false,
    },
    {
      dataField: 'storeName',
      text: 'Store Name ',
      headerStyle: { backgroundColor: '#f0f0f073' },
        sort: false,
    },
    {
      dataField: 'address',
      text: 'Address',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: false,
    },
    {
      dataField: 'city',
      text: 'City',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: false
    },
    {
      dataField: 'state',
      text: 'State',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: false
    },
    
  ];

  const formateDate = (newDate) => {
    const originalDate = new Date(newDate);
    const options = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      // timeZone: 'UTC'
    };

    const formattedDate = originalDate.toLocaleString('en-US', options)
      .replace(',', '');
    return formattedDate;
  }

  return (
    <div className="page-container">
      <div className="page-content-wrapper">
        <div className="content">
          <PageContainer className="container-fluid container-fixed-lg bg-white">

            <div className="card card-transparent">

              <div className="card-header d-flex flex-column justify-content-between ">
                <div className="d-flex flex-row justify-content-between mb-4">
                  <h3 className="text-primary no-margin text-uppercase">Work Order Summary</h3>
                  <div className='row gap-2 px-3'>
                    <div className="pl-2">
                      {csvProps && (
                        <ExportCSVButton {...csvProps}><i className="pg-icon m-r-10"><FiShare size={'15'} /></i> Export CSV</ExportCSVButton>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-flex flex-row justify-content-start'>

                <div className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0 px-1">
                  <div className="input-group">
                    <span className="input-group-text input-date-icon-pre">
                      <i className="pg-icon">search</i>
                    </span>
                    <input
                      type="text"
                      className="form-control py-2"
                      placeholder="Search by work order number"
                      name='workOrderNumber'
                      value={filters.workOrderNumber}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0 px-1 p-l-0">
                  <div className="input-group">
                    <span className="input-group-text input-date-icon-pre">
                      <i className="pg-icon">search</i>
                    </span>
                    <input
                      type="text"
                      name="storeNumber"
                      className="form-control py-2"
                      placeholder="Search by store number"
                      value={filters.storeNumber}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>

                {/* <div className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0 px-1">
                  <div className="input-group m-r-15">
                    <GroupSelect
                      placeholder='Status'
                      inputId='worksiteId'
                      value={workerStatus}
                      options={workerStatusFilterOptions}
                      onChange={(selectedOption) => setWorkerStatus(selectedOption?.value)}
                    />
                  </div>
                </div> */}

                <div className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0 px-1">
                  <GroupSelect
                    placeholder='Store Name'
                    inputId="worksiteId"
                    value={worksiteId}
                    options={worksiteOptionsForFilter}
                    onChange={(selectedOption) => setWorksiteId(selectedOption?.value)}
                  />
                </div>
              </div>

              {!isLoading ? <div className="card-body custable">
                {VisitsSummaryWithIndex?.length > 0 ? (
                  <ToolkitProvider
                    keyField="id"
                    data={VisitsSummaryWithIndex}
                    columns={tableColumns}
                    exportCSV={{
                      fileName: 'callRecords-data.csv',
                      ignoreHeader: false,
                      noAutoBOM: false,
                    }}
                  >
                    {(props) => {
                      csvPropsRef.current = props.csvProps;
                      if (!csvProps) {
                        setCsvProps(props.csvProps);
                      }
                      return (
                        <React.Fragment>
                          <BootstrapTable
                            {...props.baseProps}
                            hover={true}
                            bootstrap4
                            bordered={false}
                            condensed={true}
                            striped={true}
                            pagination={paginationFactory()}
                          // cellEdit={cellEditFactory({
                          //   mode: 'click',
                          //   blurToSave: true,
                          // })}
                          />
                        </React.Fragment>
                      )
                    }}
                  </ToolkitProvider>
                ) : (
                  <NoData />
                )}
              </div> : <Loader />}
            </div>
          </PageContainer>
        </div>
      </div>
    </div>
  );
};

export default VisitsSummary;
